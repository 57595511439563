import {lighten} from '@material-ui/core/styles';
import {light}   from './colors';
import font      from './font';

const globalTheme = {

	palette : {
		primary : {
			main  : light.primary.main,
		},
		secondary : {
			main  : light.secondary.main,
		},
		error : {
			main  : light.error.main,
		},
		background : {
			default : light.background
		}
	},
	typography : {
		...font
	},

	overrides : {
		MuiInputBase : {
			root : {
				"& input": {
					"&:-webkit-autofill": {
						transition:
						"background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
					},
					"&:-webkit-autofill:focus": {
						transition:
						"background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
					},
					"&:-webkit-autofill:hover": {
						transition:
						"background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s",
					},
				},
			}
		},
		MuiOutlinedInput : {
		},
		MuiSelect : {
			outlined : {
				paddingTop : '14px',
				paddingBottom : '14px',
			}
		},
		MuiButtonBase : {
			root : {
				height : '40px',
			}
		},
		MuiButton : {
			containedSecondary : {
				'&$disabled' : {
					backgroundColor : `${lighten(light.secondary.main, 0.3)} !important`,
				},
			},
			containedPrimary : {
				'&$disabled' : {
					backgroundColor : `${lighten(light.primary.main, 0.25)} !important`,
					color           : '#fff'
				},
			}
		},
		MuiTabs : {
			indicator : {
				display    : 'none',
			},
		},
	}
};

export default globalTheme;
