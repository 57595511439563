import React                       from 'react';
import Grid                        from '@material-ui/core/Grid';
import Hidden                      from '@material-ui/core/Hidden';
import {withSnackbar}              from 'notistack';

import TopNav                      from 'components/molecules/TopNav';
import authStore, {picProfileInfo} from 'redux/authStore';
import MobileNav                   from 'components/molecules/MobileNav';
import ApplicantCard               from 'components/molecules/ApplicantCard';
import apis                        from 'common/apis';
import Log                         from 'common/log';
import utility                     from 'common/utility';
import {errorMsg}                  from 'common/errors';
import Skeleton                    from './Skeleton';
import DownloadModal               from './DownloadModal';

const log = Log ('JapaneseClass', 'info');

class JapaneseClass extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav     : false,
			loading       : true,
			picProfile    : null,
			orders        : [],
			cart          : [],
			applicants    : [],
			downloadModal : null,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {

		this.getProfile ();
		this.getCart ();
		this.getApplicantsList ();
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
		this.setState ({
			picProfile,
			jobId,
		});
	}

	getApplicantsList = async () => {
	
		let applicants;

		try {
			applicants = await apis.getApplicantsList ();
			log.info ({applicants, }, 'applicants list for jclass get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting applicants for jclass');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			applicants,
			loading : false,
		});
	}

	openDownloadModal = (applicant) => {

		this.setState ({
			downloadModal : applicant,
		});
	}

	renderApplicants = () => {
		let __applicants = this.state.applicants;
		let cards = [];

		for (let i = 0; i < __applicants.length; i++) {
			cards.push (
				<ApplicantCard page = 'JCLASS' applicant = {__applicants[i]} key = {__applicants[i].id} onDownload = {this.openDownloadModal}/>
			);
		}
		return cards;
	}

	render () {

		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid container spacing = {3} className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid item lg = {1} xl = {2}>
					</Grid>
					<Grid item lg = {10} xl = {8} md = {12} sm = {12} xs = {12} className = 'mt-36'>
						{this.state.loading ? <Skeleton /> : this.renderApplicants ()}
					</Grid>
					<Grid item lg = {1} xl = {2}>
					</Grid>
				</Grid>
				{this.state.downloadModal ? <DownloadModal applicant = {this.state.downloadModal} close = {() => this.setState ({downloadModal : null})}/> : null}
			</Grid>
		);
	}
}

export default withSnackbar(JapaneseClass);
