/*This file is to check the authentication whenever the users first login*/

import apis from  './apis';

const auth = {};

auth.verifyUser = async () => {

	let token = localStorage.getItem ('token');

	if (token === 'undefined' || token === null || !token) {
		return false;
	}

	let user = await apis.getProfile ();
	return user;
};

export default auth;
