import {light} from 'styles/muiTheme/colors';

export const styles = {
	
	btnUnselcted : {
		color  : '#777777',
		border : '1px solid #C3C3C3'
	},

	btnSelected : {
		color : light.primary.main,
		border : `1px solid ${light.primary.main}`
	},

	dialogTitle : {
		fontWeight : 'bold'
	},

	closeButton : {
		position : 'absolute',
		top      : '12px',
		right    : '12px',
	},

	downloadBtn : {
		cursor : 'pointer',
	}
};
