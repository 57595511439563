import {createStore} from 'redux';

let initState = {
	applicants : [],
	appCount : null,
};

export function applicantsList (applicants) {
	
	return {
		type        : 'APPLICANTS_LIST',
		applicants  : applicants,
	};
}

export function updateAppCount (count) {
	return {
		type : 'APP_COUNT',
		appCount : count,
	};
}

function applicantReducer (applicantStore = initState, action) {
	
	switch (action.type) {

		case 'APPLICANTS_LIST' :
			return {
				...applicantStore,
				landingDetails : action.applicants,
			};

		case 'APP_COUNT' :
			return {
				...applicantStore,
				appCount : action.appCount,
			};

		default :
			return initState;
	}
}

const applicantStore = createStore (applicantReducer, initState);

export default applicantStore;
