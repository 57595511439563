export default function Applicantfilter (applicants, filters) {

	if (!Array.isArray(applicants)) {
		return;
	}
	let __filters = [];

	for (let i = 0; i < filters.length; i++) {
		if (Array.isArray (filters[i].value) && !filters[i].value.length) {
			continue;
		}
		if (!filters[i].value) {
			continue;
		}
		__filters.push (filters[i]);
	}

	if (!__filters.length) {
		return applicants;
	}

	let filtered = applicants;
	for (let i = 0; i < __filters.length; i++) {
		filtered = filteredList (__filters[i], filtered);
	}
	return filtered;
}

function filteredList (filter, applicants) {

	let filtered = [];
	for (let i = 0; i < applicants.length; i++) {
		let customField = applicants[i].custom_fields;
		if (Array.isArray(customField[filter.name]) && commonElement (customField[filter.name], filter.value)) {
			filtered.push (applicants[i]);
			continue;
		}
		if (customField[filter.name] === filter.value) {
			filtered.push (applicants[i]);
			continue;
		}
	}
	return filtered;
}

function commonElement (arr1, arr2) {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		return false;
	}
	arr1 = arr1.map(item => item.trim());
	arr2 = arr2.map (item => item.trim ());
	return arr1.some(item => arr2.includes(item));
}
