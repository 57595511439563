import React, {useEffect}    from 'react';
import moment                from 'moment';
import {withSnackbar}        from 'notistack';
import {Redirect, NavLink}   from 'react-router-dom';
import { withStyles }        from '@material-ui/core/styles';
import Hidden                from '@material-ui/core/Hidden';
import Badge                 from '@material-ui/core/Badge';
import Link                  from '@material-ui/core/Link';
import IconButton            from '@material-ui/core/IconButton';
import AppBar                from '@material-ui/core/AppBar';
import Grid                  from '@material-ui/core/Grid';
import Typography            from '@material-ui/core/Typography';
import Toolbar               from '@material-ui/core/Toolbar';
import Button                from '@material-ui/core/Button';
import ClickAwayListener     from '@material-ui/core/ClickAwayListener';
import Grow                  from '@material-ui/core/Grow';
import Paper                 from '@material-ui/core/Paper';
import Popper                from '@material-ui/core/Popper';
import MenuItem              from '@material-ui/core/MenuItem';
import MenuList              from '@material-ui/core/MenuList';
import ArrowDropDownIcon     from '@material-ui/icons/ArrowDropDown';
import MenuIcon              from '@material-ui/icons/Menu';

import {styles}              from './style';
import ChangePassword        from 'components/molecules/ChangePassword';
import apis                  from 'common/apis';
import path                  from 'common/path';
import {errorMsg}            from 'common/errors';
import utility               from 'common/utility';
import {navigations}         from 'common/config';
import authStore             from 'redux/authStore';
import applicantStore        from 'redux/applicantStore';
import { updateAppCount }    from 'redux/applicantStore';
import { light }             from 'styles/muiTheme/colors';

const StyledBadge = withStyles((theme) => ({
	badge : {
		right      : -3,
		top        : 13,
		border     : `2px solid ${theme.palette.background.paper}`,
		padding    : '0 4px',
		color      : '#fff',
		background : '#e64021',
		fontSize   : '13px',
		maxWidth   : '102px'
	},
}))(Badge);

function TopNav (props) {

	const [open, setOpen]                   = React.useState (false);
	const [passDialog, setPassDialog]       = React.useState (false);
	const [redirectLogin, setRedirectLogin] = React.useState (false);
	const [appCount, setAppCount]           = React.useState (null);
	const anchorRef                         = React.useRef   (null);
	const [routes, setRoutes]               = React.useState ([]);
	const [lastLogin, setLastLogin]         = React.useState ("");
	const notify                            = props.enqueueSnackbar;
	
	useEffect (() => {
		getAuthInfo ();
		getApplicantsCount ();
	}, []);

	useEffect (() => {
		return () => {
			authUnsub;
			applicantUnsub;
		};
	}, []);

	const getAuthInfo = () => {
		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;
		let _lastLogin = picProfile.last_sign_in_at;
		_lastLogin = moment(_lastLogin).format ('YYYY/MM/DD');
		setLastLogin (_lastLogin);

		let isOnboarded = picProfile && picProfile['is_onboarded'];
		let __navigations = [...navigations];

		if (isOnboarded) {
			setRoutes(__navigations);
			return;
		}
		setRoutes (__navigations.filter(nav => nav.link !== path.onboarding));
	};

	const getApplicantsCount = async () => {
		let applicantInfo = applicantStore.getState ();
		let count = applicantInfo.appCount;
		if (count === null) {
			try {
				count = await apis.getApplicantCount();
			}
			catch (err) {
				if (err.response && err.response.status === 401) {
					this.notify(errorMsg.logoutMsg, { variant: 'error' });
					utility.redirectLogin();
					return;
				}
				return;
			}
			if (count.errors) {
				notify(count.errors, { variant: 'error' });
				return;
			}
			applicantStore.dispatch (updateAppCount (count.count));
			return;
		}
		setAppCount(count);
	};

	const authUnsub = authStore.subscribe (getAuthInfo);
	const applicantUnsub = applicantStore.subscribe (getApplicantsCount);

	const handleToggle = () => {

		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {

		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = (event) => {

		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	const onLogout = async () => {

		let result;
		try {
			result = await apis.logout ();
		}
		catch (err) {
			if (err.response.status === 401) {
				notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			return;
		}
		if (result.success) {
			localStorage.removeItem ('token');
			localStorage.removeItem ('lts');
			setRedirectLogin (true);
		}
		window.location.reload();
	};

	/*
	const changePassword = () => {

		setPassDialog (true);
		setOpen (false);
	};
	*/

	const closePassModal = () => {
		
		setPassDialog (false);
	};

	if (redirectLogin) {
		return (
			<Redirect to = {path.login} />
		);
	}

	return (
		<AppBar position = 'static' color = 'secondary' style = {styles.navbar}>
			<Toolbar style = {styles.toolbar} disableGutters = {false}>
				{passDialog ? <ChangePassword closeModal = {closePassModal}/> : null}
				<Grid container spacing = {2}>
					<Grid item xs = {2} sm = {1} md = {7} lg = {8} xl = {9} container alignItems = 'center'>
						<Hidden only = {['xs', 'sm']}>
							{routes.map ((nav, index) => (
								nav.link === path.landing ?
									<Link component={NavLink} exact to={nav.link} style={styles.navLink} key={index} id='navlink'> {nav.name + " "}
										<strong style = {{color : light.error.main}}> {appCount !== null ? appCount : ''}</strong>
									</Link>
									:
									<Link component={NavLink} exact to={nav.link} style={styles.navLink} key={index} id='navlink'> {nav.name} </Link>
							))}
						</Hidden>
						<Hidden only = {['md', 'lg', 'xl']}>
							<IconButton color = "inherit" edge = "start" onClick = {props.mobileNav}>
								<MenuIcon />
							</IconButton>
						</Hidden>
					</Grid>
					<Grid container justify = 'flex-end' alignItems = 'center' item xs = {10} sm = {11} md = {5} lg = {4} xl = {3}>
						<StyledBadge badgeContent={props.cartNumber} color="secondary" className = 'mr-12'>
							<Button variant = 'contained' component = {NavLink} to = {path.checkout} className = 'designed-button-contained' style = {{color : 'white'}}>  検討リスト </Button>
						</StyledBadge>
						<Button color="inherit" disableRipple onClick = {handleToggle} ref = {anchorRef} style = {styles.nameBtn}>
							<Typography variant = 'body2'> {props.name}</Typography>
							<ArrowDropDownIcon />
						</Button>
						<Popper
							open     = {open}
							anchorEl = {anchorRef.current}
							transition
							disablePortal
							style    = {styles.popper}
						>
							{({TransitionProps, placement}) => (
								<Grow
									{...TransitionProps}
									style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
											<MenuList autoFocusItem = {open} onKeyDown={handleListKeyDown}>
												<MenuItem onClick = {onLogout}> ログアウト </MenuItem>
												<MenuItem> 最終ログイン： {lastLogin} </MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}

export default withSnackbar(TopNav);
