import React                      from 'react';
import _                          from 'lodash';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	text: {
		fontFamily: 'Lato',
		fontSize: 11,
	},
	textBold: {
		fontFamily: 'Lato Bold',
		fontSize: 11,
	},
});

export default (props) => {
	const { studentData } = props;
	let customField = studentData.custom_fields;

	let 名前   = _.startCase(_.camelCase(studentData.name));
	let Email  = studentData.email;
	let 住所   = customField.find (c => c.fields["住所"]);
	let Skype  = customField.find (c => c.fields["SkypeID"]);
	let wechat = customField.find (c => c.fields['WeChat ID']);
	住所       = 住所 ? 住所.fields["住所"] : "";
	Skype      = Skype ? Skype.fields["SkypeID"] : "";
	wechat     = wechat ? wechat.fields['WeChat ID'] : '';

	return(
		<View>
			{名前  ? <Text style={styles.text}>名前: {_.startCase(_.camelCase(studentData.name))} </Text> : null}
			{Email ? <Text style={styles.text}>Email: {Email} </Text> : null}
			{住所  ? <Text style={styles.text}>住所 : {住所} </Text> : null}
			{Skype ? <Text style={styles.text}>Skype : {Skype} </Text> : null}
			{wechat ? <Text style={styles.text}>WeChat : {wechat} </Text> : null}
		</View>
	);
};
