import React       from 'react';
import Grid        from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import Logo        from 'assets/Logo.png';
import {styles}    from './style';

function ResetMessage () {
	return (
		<Grid container spacing = {3} style = {styles.mainContainer} alignItems = 'center' justify = 'center'>
			<Grid item xs = {12} sm = {8} md = {6} lg = {4} xl = {4}>
				<Grid style = {styles.loginContainer}>
					<Grid container justify = 'center'>
						<img alt= 'Logo' src = {Logo} height = {65} width = {70} className = 'mb-24'/>
					</Grid>
					<Typography variant = 'h6' align = 'center'> パスワードリセットについて、手順を記載したーメールをお送りしました。お使いのメールをご確認ください。</Typography>
				</Grid>
			</Grid>
		</Grid>

	);
}

export default ResetMessage;
