export const light = {

	primary : {
		main  : '#288BFA',
	},
	secondary : {
		main  : '#FFF',
	},
	error : {
		main  :'#C33934',
	},
	success : {
		main : '#4CAF50',
	},
	info : {
		main : '#2196F3',
	},
	warning : {
		main : '#FF9800',
	},
	backgroundContrast      : '#FFFFFF',
	background              : '#F5F5F5',
	backgroundContrastPaper : '#F4F4F4',
	color                   : '#373737',
	contrastThreshold       : 3,                   //Used to maximize the contrast between the background and the text.

};
