import React                       from 'react';
import { Text, View, StyleSheet }  from '@react-pdf/renderer';
import Title                       from '../Title';

const styles = StyleSheet.create({
	container: {
		flexShrink : 1
	},
	text : {
		fontFamily: 'Lato',
		fontSize: 11,
		wordBreak : 'break-word',
		display: 'flex',
		flexDirection:'row',
		flexWrap: 'wrap',
		width : '100%'
	},
});

export default ({ studentData }) => {
	let customField = studentData.custom_fields;
	let インターンシップ = customField.find (c => c.fields["インターンシップ"]);
	インターンシップ = インターンシップ ? インターンシップ.fields["インターンシップ"] : "";
	インターンシップ = インターンシップ.trim ();

	let parts =  インターンシップ.match(/[\s\S]{1,90}/g) || [];
 
	return (
		<View style = {styles.container}>
			{インターンシップ ?
				<View>
					<Title> インターンシップ(Internship) </Title>
					{parts.map ((p,i) => (
						<Text style = {styles.text} key ={i}> {p} </Text>
					))}
				</View>
				: null}
		</View>
	);
};
