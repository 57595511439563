import React                from 'react';
import Dialog               from '@material-ui/core/Dialog';
import {withSnackbar}       from 'notistack';
import Link                 from '@material-ui/core/Link';
import DialogContent        from '@material-ui/core/DialogContent';
import Divider              from '@material-ui/core/Divider';
import Typography           from '@material-ui/core/Typography';
import Grid                 from '@material-ui/core/Grid';
import Loader               from 'components/atoms/Loader';
import apis                 from 'common/apis';
import Log                  from 'common/log';
import utility              from 'common/utility';
import {errorMsg}           from 'common/errors';
import {styles}             from './style';

const log = Log ('DownloadModal', 'info');

class DownloadModal extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			loading : '',
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		this.setState ({
			applicantId : this.props.applicant.id,
		});
	}

	download = async (id) => {
		
		this.setState ({
			loading : id,
		});

		let jsonData = {
			jclass_pdf_history : {
				jclass_pdf_history_id : id,
			}
		};

		let link;
		try {
			link =  await apis.jclassPdfDownloadLink (jsonData);
		}
		catch (err) {
			log.error ({err}, 'error in downloading file');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				this.setState ({
					loading : '',
				});
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		if (!link.download_url) {
			this.setState ({
				loading : '',
			});
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			loading : '',
		});

		const url  = link.download_url;
		var isSafari = window.safari !== undefined;
		if (isSafari) {
			return url ?
				window.open(url, '_blank') : null;
		}
		if (window.navigator.msSaveOrOpenBlob) {
			return url ?
				window.navigator.msSaveOrOpenBlob(url, this.state.applicantId + ".pdf") :  null;}
		else {
			return url ?
				window.open(url, '_blank') : null; }
	}

	render () {
		return (
			<Dialog open = {true} maxWidth = 'sm' fullWidth = {true} onBackdropClick = {this.props.close}>
				<DialogContent style = {{overflow : 'hidden'}}>
					<Grid container spacing = {3}>
						<Grid item sm = {7}>
							<Typography variant = 'h6'>　タイトル　</Typography>
						</Grid>
						<Grid item sm = {5}>
							<Typography variant = 'h6'></Typography>
						</Grid>
					</Grid>
					<Divider className = 'mt-12 mb-12'/>
					{this.props.applicant.reflection_documents.map (item => (
						<Grid container spacing = {3} key = {item.name}>
							<Grid item sm = {7}>
								<Typography variant = 'body1'> {item.name} </Typography>
							</Grid>
							<Grid item sm = {5}>
								<Link color = 'primary' style = {styles.downloadBtn} onClick = {() => this.download(item.id)}> {this.state.loading === item.id ? <Loader/> : 'ダウンロード'} </Link>
							</Grid>
						</Grid>
					))}
				</DialogContent>
			</Dialog>
		);
	}
}

export default withSnackbar(DownloadModal);
