import React                            from 'react';
import logo                             from 'assets/Logo.png';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

function Header (props)  {
	const { studentData } = props;
	return (
		<View style = {styles.container} fixed>
			<Image style = {styles.logo} src = {logo}/>
			<View style = {styles.detailColumn}>
				<Text style = {styles.name}> {studentData.id}    {studentData.name}</Text>
				<Text style = {styles.subtitle}> Fast Offer International </Text>
			</View>
		</View>
	);
}

export default Header;

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		borderBottomWidth: 2,
		borderBottomColor: '#112131',
		borderBottomStyle: 'solid',
		alignItems: 'stretch',
		marginBottom: 10,
	},
	detailColumn: {
		flexDirection: 'column',
		flexGrow: 9,
		marginBottom: 5,
	},
	name: {
		fontSize: 24,
		textTransform: 'uppercase',
		fontFamily: 'Lato Bold',
	},
	subtitle: {
		fontSize: 10,
		justifySelf: 'flex-end',
		textTransform: 'uppercase',
		fontFamily: 'Lato',
	},
	logo: {
		height: 50
	}
});
