import React              from 'react';
import {Text, StyleSheet} from '@react-pdf/renderer';

const Footer = () => {
	
	return (
		<Text style={styles.footer}>STUDY GO WORK JAPAN</Text>
	);
};

export default Footer;

const styles =  StyleSheet.create ({
	footer: {
		fontSize: 12,
		fontFamily: 'Lato',
		textAlign: 'center',
		marginTop: 0,
		padding: 5,
		borderWidth: 1,
		borderColor: 'gray',
		borderStyle: 'dashed',
		'@media orientation: landscape': {
			marginTop: 10,
		},
	},
});
