import React                       from 'react';
import { Text, View, StyleSheet }  from '@react-pdf/renderer';
import Title                       from '../Title';

const styles = StyleSheet.create({
	container: {
	},
	text : {
		fontFamily: 'Lato',
		fontSize: 11,
	},
});

export default ({ studentData }) => {
	let customField     = studentData.custom_fields;
	let IT_Skills       = customField.find (c => c.fields["IT Skills"]);
	let other_IT_Skills = customField.find (c => c.fields["other IT Skills"]);
	let Github          = customField.find (c => c.fields["Github"]);

	IT_Skills       =  IT_Skills ?  IT_Skills.fields["IT Skills"] : "";
	other_IT_Skills = other_IT_Skills ? other_IT_Skills.fields["other IT Skills"] : "";
	Github          = Github ? Github.fields["Github"] : "";
	
	IT_Skills = Array.isArray (IT_Skills) ? IT_Skills.join (", ") : IT_Skills;
	other_IT_Skills = Array.isArray (other_IT_Skills) ? other_IT_Skills.join (", ") : other_IT_Skills;

	return (
		<View style = {styles.container}>
			<Title> Skills </Title>
			{IT_Skills || other_IT_Skills ? <Text style = {styles.text}>  Skills : {IT_Skills}, {other_IT_Skills} </Text> : null}
			{Github ? <Text style = {styles.text}> Github : {Github} </Text> : null}
		</View>
	);
};
