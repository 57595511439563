import React                                                 from 'react';
import {Redirect, Link as Navlink}                           from 'react-router-dom';
import {withSnackbar}                                        from 'notistack';
import Link                                                  from '@material-ui/core/Link';
import Grid                                                  from '@material-ui/core/Grid';
import Typography                                            from '@material-ui/core/Typography';
import FormHelperText                                        from '@material-ui/core/FormHelperText';
import IconButton                                            from '@material-ui/core/IconButton';
import TextField                                             from '@material-ui/core/TextField';
import Button                                                from '@material-ui/core/Button';
import Visibility                                            from '@material-ui/icons/Visibility';
import VisibilityOff                                         from '@material-ui/icons/VisibilityOff';

import utility                                               from 'common/utility';
import apis                                                  from 'common/apis';
import path                                                  from 'common/path';
import Log                                                   from 'common/log';
import authentication                                        from 'common/auth';
import authStore, {picProfileInfo, landingInfo, selectedJob} from 'redux/authStore';
import Loader                                                from 'components/atoms/Loader';
import Logo                                                  from 'assets/atoj-logo.png';
import {styles}                                              from './style';

const log = Log ('Login', 'info');

class Login extends React.Component {
	
	constructor (props) {
		super (props);

		this.state = {
			email        : '',
			password     : '',
			showPassword : false,
			errors       : {
				email    : false,
				password : false
			},
			loginSuccess : false,
			loginErrMsg  : '',
			isLoading    : false,
			verifying    : true,
			isAuthenticated : false,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = async () => {

		let urlString = window.location.href;
		let url       = new URL(urlString);
		let token     = url.searchParams.get ('auth_token');

		if (token) {
			this.onLoginSuccess (token);
		}

		let isVerifed;
		try {
			isVerifed = await authentication.verifyUser ();
		}
		catch (err) {
			log.error ({err}, 'login authentication failed');
			this.setState ({
				verifying : false,
			});
			return;
		}

		if (!isVerifed) {
			this.setState ({
				verifying : false,
			});
			return;
		}

		authStore.dispatch (picProfileInfo (isVerifed));
		this.setState ({
			isAuthenticated : true,
			verifying : false,
		});
	}

	handleChange = (event) => {
		
		const {name, value} = event.currentTarget;

		this.setState ({
			[name] : value,
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleEnterPress = (event) => {
		
		if (event.keyCode !== 13) {
			return;
		}

		this.onLogin ();
	}

	showPassword = () => {
		
		this.setState ({
			showPassword : true,
		});
	}

	hidePassword = () => {
	
		this.setState ({
			showPassword : false,
		});
	}

	onLogin = async () => {
		
		let email    = this.state.email;
		let password = this.state.password;
		let errors = {};

		if (!utility.validateEmail (email)) {
			errors.email = true;
		}

		if (!password || password.trim () === "") {
			errors.password = true;
		}

		if (Object.keys (errors).length) {
			this.setState ({
				errors : {
					...errors
				}
			});
			return;
		}

		let data = {
			email,
			password,
		};

		let result;

		this.setState ({
			isLoading : true,
		});

		try {
			result = await apis.login (data);
			log.info ({user : result}, 'getting user info');
		}

		catch (err) {
			log.error ({err}, 'failed to login');
			this.notify ('予期しないエラーが発生しました', {variant : 'error'});
			this.setState ({
				isLoading : false,
			});
			return;
		}

		if (result.errors) {
			this.setState ({
				loginErrMsg : result.errors,
				isLoading   : false,
			});
			return;
		}
	
		this.onLoginSuccess (result.access_token.token, result);
	}

	onLoginSuccess = async(token, result) => {
	
		let loginTs = new Date ().getTime (); //maintaining when the user first login to the browser.

		localStorage.setItem ('token', token);
		localStorage.setItem ('lts', loginTs);

		if (!result) {
			result = await apis.getProfile ();
			log.info ({picProfile : result}, 'pic profile get ok');
			result = {
				access_token : {
					token,
				},
				company_pic : {
					email  : result.email,
					id : result.id,
					name : result.name,
				},
				success: true,
				...result,
			};
			let jobId = result.jobs.length ? result.jobs[0].job_id : null;
			authStore.dispatch (selectedJob (jobId));
		}

		authStore.dispatch (landingInfo (result));
		authStore.dispatch (picProfileInfo (result.company_pic));

		this.setState ({
			loginSuccess : true,
			isLoading    : false,
		});
	}

	renderEndAdornment = () => {
		
		return (
			<IconButton
				size          = 'small'
				onMouseDown   = {this.showPassword}
				onMouseUp     = {this.hidePassword}
				onPointerDown = {this.showPassword}
				onPointerUp   = {this.hidePassword}
				style         = {styles.iconButton}
			>
				{!this.state.showPassword ? <Visibility style = {styles.icon}/> : <VisibilityOff style = {styles.icon}/>}
			</IconButton>
		);
	}

	render () {

		if (this.state.verifying) {
			return (
				<div style = {{height : '100%', background : 'white'}}> </div>
			);
		}

		if (this.state.loginSuccess || this.state.isAuthenticated) {
			return (
				<Redirect to = {path.landing} push = {true}/>
			);
		}
		
		return (
			<Grid container spacing = {3} style = {styles.mainContainer} alignItems = 'center' justify = 'center'>
				<Grid item xs = {12} sm = {8} md = {6} lg = {4} xl = {4}>
					<Grid style = {styles.loginContainer}>
						<Grid container justify = 'center'>
							<img src = {Logo} height = {50} width = {50} className = 'mb-24'/>
						</Grid>
						<Typography variant = 'h4' align = 'center' className = 'mb-16'> サインイン </Typography>
						<Grid style = {styles.inputContainer}>
							<TextField
								label       = 'メール'
								variant     = 'outlined'
								value       = {this.state.email}
								error       = {this.state.errors.email}
								fullWidth   = {true}
								name        = 'email'
								onChange    = {this.handleChange}
								type        = 'email'
								onKeyUp     = {this.handleEnterPress}
							/>
							{this.state.errors.email ? <FormHelperText error = {true}> メールアドレスは有効ではありません。 </FormHelperText> : null}
						</Grid>
						<Grid style = {styles.inputContainer}>
							<TextField
								label       = 'パスワード'
								variant     = 'outlined'
								value       = {this.state.password}
								error       = {this.state.errors.password}
								fullWidth   = {true}
								onChange    = {this.handleChange}
								type        = {this.state.showPassword ? 'text' : 'password'}
								name        = 'password'
								onKeyUp     = {this.handleEnterPress}
								InputProps  = {{
									style : styles.inputProps,
									endAdornment : this.renderEndAdornment ()
								}}
							/>
							{this.state.errors.password ? <FormHelperText error = {true}> パスワードは必須です。 </FormHelperText> : null}
						</Grid>
						<Button variant = 'contained' className = 'mt-12' color = 'primary' fullWidth onClick = {this.onLogin} disabled = {this.state.isLoading}>
							{this.state.isLoading ? <Loader /> : 'サインイン'}
						</Button>
						<Grid className = 'mt-12' container justify = 'center'>
							<Link color = 'primary' component = {Navlink} to = {path.forgotPassword}> パスワードをお忘れですか？ </Link>
						</Grid>
					</Grid>
					{this.state.loginErrMsg ? <FormHelperText error = {true} style = {styles.loginErrMsg}> {this.state.loginErrMsg} </FormHelperText> : null}
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Login);