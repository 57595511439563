import React                                                  from 'react';
import {Redirect, Route}                                      from 'react-router-dom';

import authentication                                         from 'common/auth';
import paths                                                  from 'common/path';
import authStore, {picProfileInfo, selectedJob, isredirected} from 'redux/authStore';
import Log                                                    from 'common/log';

const log = Log ('PrivateRoute', 'info');

class PrivateRoute extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			loading         : true,
			isAuthenticated : false,
		};
	}

	componentDidMount = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			let pic;
			try {
				pic = await authentication.verifyUser ();
				log.info ({results : pic}, 'profile info get ok');
			}
			catch (err) {
				log.error ({err}, 'error getting profile info');
				this.setState ({
					loading : false,
				});
				return;
			}
			if (!pic) {
				this.setState ({
					loading : false,
				});
				return;
			}
			let jobId = pic.jobs.length ? pic.jobs[0].job_id : null; //also fetch applicants if jobId is null
			authStore.dispatch (selectedJob (jobId));
			authStore.dispatch (picProfileInfo (pic));
			if (window.location.pathname !== paths.landing) {
				authStore.dispatch (isredirected (true));
			}
			this.setState ({
				loading         : false,
				isAuthenticated : true,
			});
			return;
		}
		this.setState ({
			loading         : false,
			isAuthenticated : true,
		});
	}

	render () {

		const { component: Component, ...rest } = this.props;
		if (this.state.loading) {

			/*TODO 
			 *Add some loader page here
			 * */
			return (
				<div></div>
			);
		}

		return (
			<Route {...rest} render={(props) => (
				this.state.isAuthenticated === true
					? <Component {...props} />
					: <Redirect to = {{
						pathname : paths.login,
						state    : { from : props.location }
					}} />
			)} />
		);
	}
}

export default PrivateRoute;
