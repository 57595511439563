export const styles = {
	
	mainContainer : {
		height       : '100%',
		background   : 'var(--white)',
		padding      : '1rem',
		marginTop    : '0px',
		marginBottom : '0px',
	},

	loginContainer : {
		padding      : '48px 40px 36px',
		border       : '1px solid rgba(34,36,38,.15)',
		boxShadow    : '0 1px 2px 0 rgba(34,36,38,.15)',
		borderRadius : '4px',
		maxWidth     : '450px',
		margin       : '0px auto',
		textAlign    : 'center',
	},

	inputContainer : {
		margin : '0px 0px 1em 0px',
		width  : '100%'
	},

	inputProps : {
		paddingLeft : '0px',
	},

	icon : {
		height : '0.8em',
		width  : '0.8em',
		color  : '#909090',
		margin : '10px',
	},

	iconButton : {
		padding : '0px'
	},

	loginErrMsg : {
		marginTop : '12px',
		textAlign : 'center',
		fontSize  : '0.875em',
	}
};
