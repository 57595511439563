import React                            from 'react';
import {withSnackbar}                   from 'notistack';
import Grid                             from '@material-ui/core/Grid';
import Paper                            from '@material-ui/core/Paper';
import Typography                       from '@material-ui/core/Typography';
import Hidden                           from '@material-ui/core/Hidden';
import Select                           from '@material-ui/core/Select';
import MenuItem                         from '@material-ui/core/MenuItem';

import TopNav                           from 'components/molecules/TopNav';
import Pagination                       from 'components/molecules/Pagination';
import MobileNav                        from 'components/molecules/MobileNav';
import ApplicantCard                    from 'components/molecules/ApplicantCard';
import authStore, {picProfileInfo}      from 'redux/authStore';
import apis                             from 'common/apis';
import Log                              from 'common/log';
import {errorMsg}                       from 'common/errors';
import utility                          from 'common/utility';
import Skeleton                         from './Skeleton';

const log = Log ('InterviewHistory', 'info');

class InterviewHistory extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav        : false,
			loading          : true,
			isFetched        : false,
			picProfile       : null,
			cart             : [],
			interviewHistory : [],
			events           : [],
			selectedEvent    : '',
			applicants       : [],
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		this.getProfile ();
		this.getCart ();
		this.getInterviewHistory ();
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	handleEventChange = (event) => {
		const value = event.target.value;
		let sgwj_event = this.state.interviewHistory.find (e => e.event_id === value);
		if (!sgwj_event) {
			return;
		}
		this.setState ({
			applicants : sgwj_event.applicants,
			selectedEvent : value,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
				isFetched : true,
			});
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.setState ({
					loading : false,
					isFetched : true,
				});
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs[0] ? picProfile.jobs[0].job_id : '';
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs[0] ? picProfile.jobs[0].job_id : '';
		this.setState ({
			picProfile,
			jobId,
		});
	}

	getInterviewHistory = async () => {
		
		let interviewHistory;

		try {
			interviewHistory = await apis.interviewHistory ();
		}
		catch (err) {
			log.error ({err}, 'error getting interview hietory');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
				isFetched : true,
			});
			return;
		}
		log.info ({interviewHistory}, 'interveiw history get ok');

		let events = interviewHistory.map (i => {
			return {
				sgwj_event_id : i.event_id,
				sgwj_event_name : i.event_name,
			};
		});

		if (!events.length) {
			this.setState ({
				loading : false,
				isFetched : true,
			});
			return;
		}

		this.setState ({
			interviewHistory,
			events,
			selectedEvent : events[0].sgwj_event_id,
			applicants : interviewHistory[0].applicants,
			loading : false,
			isFetched : true,
		});
	}

	renderInterviewHistory = () => {
		
		if (!this.state.applicants.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 面接履歴はありません </Typography>
			);
		}

		return (
			<Pagination data = {this.state.applicants.map ((applicant, index) => (
				<ApplicantCard
					applicant  = {applicant}
					key        = {index}
					interviews = {applicant.interviews}
				/>
			))}
			/>
		);
	}

	render () {
	
		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing = {3}>
						<Grid item lg = {1} xl = {2}>
						</Grid>
						<Grid item lg = {10} xl = {8} md = {12} sm = {12} xs = {12}>
							<Paper elevation = {2} className = 'p-16'>
								<Grid container spacing = {2} alignItems = 'center'>
									<Grid item sm = {6}>
										<Typography variant = 'h4'> 面接履歴 </Typography>
									</Grid>
									<Grid container alignItems='center' style= {{gap : 16}} item sm = {6} justify = 'flex-end'>
										{this.state.applicants.length ?
											<>
												<Typography>面接会参加月</Typography>
												<Select value = {this.state.selectedEvent} variant = 'outlined' onChange = {this.handleEventChange}>
													{this.state.events.map (e => (
														<MenuItem value = {e.sgwj_event_id} key = {e.sgwj_event_id}> {e.sgwj_event_name}</MenuItem>
													))}
												</Select>
											</>
											: null
										}
									</Grid>
								</Grid>
							</Paper>
							{this.state.loading ? <Skeleton /> : this.renderInterviewHistory ()}
						</Grid>
						<Grid item lg = {1} xl = {2}>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(InterviewHistory);
