import React                from 'react';
import Dialog               from '@material-ui/core/Dialog';
import Typography           from '@material-ui/core/Typography';
import Divider              from '@material-ui/core/Divider';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import IconButton           from '@material-ui/core/IconButton';
import Fail                 from '@material-ui/icons/Clear';
import {styles}             from './style';

function InfoModal (props) {

	return (
		<Dialog open = {true} maxWidth = 'md' fullWidth = {true} onBackdropClick = {props.onClose}>
			<DialogTitle disableTypography>
				<IconButton size = 'small' style = {styles.closeButton} onClick = {props.onClose}> <Fail/></IconButton>
			</DialogTitle>
			<Divider className = 'mt-24'/>
			<DialogContent className = 'p-24'>
				<Typography variant = 'h6' className = 'mb-12'> 過去の実績（優秀な学生を獲得するために）</Typography>

				<Typography variant = 'h6'>
					ASIA to JAPANでは約150社1000名を超える過去の面接実績とヒアリングの結果に基づき
				</Typography>
				<Typography variant = 'h6'>
					入社に至りやすい学生（候補者）を「検討リスト」に掲載しております。
				</Typography>
				<Typography variant = 'h6'>
					以下、これまでの入社実績となります。ご確認ください。
				</Typography>

				<Typography variant = 'h6' className = 'mt-12 mb-12'> （本資料は、お申込書でお約束いただいた採用活動のみ使用いただけるものとなります。外部への転用は固く禁じさせていただいております）</Typography>

				<Typography variant = 'h6' className = 'mt-12 mb-12'>〈1：面接実施から入社率〉</Typography>

				<Typography variant = 'h6'>
				8名の面接を実施すれば87%の確率で入社が実現しています。
				</Typography>
				<Typography variant = 'h6'> 面接人数　内定承諾率（1名以上の内定承諾に至る割合）</Typography>
				<div><span> 1名           </span>7％</div>
				<div><span> 2名           </span>30％</div>
				<div><span> 3名           </span>26％</div>
				<div><span> 4名           </span>47%</div>
				<div><span> 5名           </span>56%</div>
				<div><span> 6名           </span>21％</div>
				<div><span> 7名           </span>36% </div>
				<div><span> 8名           </span>87% </div>
				<div><span> 16名以上      </span>93％</div>

				
				<Typography variant = 'h6' className = 'mt-12 mb-12'>〈２：留学生と海外大生/内定からの辞退率〉</Typography>

				 <Typography variant = 'h6'>・国内在住の留学生の内定からの辞退率は32% </Typography>
				 <Typography variant = 'h6'>・海外にいる外国人学生の内定からの辞退率は6%</Typography>
				 <Typography> (上記内定はStudy Go Work JAPAN内での競合の内定は含みません) </Typography>
			</DialogContent>
		</Dialog>
	);
}

export default InfoModal;
