import React          from 'react';
import Button         from '@material-ui/core/Button';
import Menu           from '@material-ui/core/Menu';
import MenuItem       from '@material-ui/core/MenuItem';
import ListItemText   from '@material-ui/core/ListItemText';

import Loader         from 'components/atoms/Loader';

export default function ButtonMenu (props) {

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [loader, setLoader]     = React.useState (false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onMenuClick = async(item) => {
		setLoader (true);
		handleClose ();
		await item.action (item.job_id);
		setLoader (false);

	};

	return (
		<div>
			<Button aria-controls="customized-menu" aria-haspopup="true" variant="outlined" color="primary" className = 'mt-12' fullWidth onClick={handleClick}>
				{loader ? <Loader color = 'primary' /> : props.btn}
			</Button>
			<Menu
				anchorEl           = {anchorEl}
				open               = {Boolean(anchorEl)}
				onClose            = {handleClose}
				elevation          = {1}
				getContentAnchorEl = {null}
				keepMounted        = {true}
				anchorOrigin       = {{
					vertical   : 'bottom',
					horizontal : 'left',
				}}
				transformOrigin    = {{
					vertical   : 'top',
					horizontal : 'left',
				}}
			>
				{props.menu.map ((item, index) => (
					<MenuItem key = {index} onClick = {() => onMenuClick(item)}>
						<ListItemText> {item.name} </ListItemText>
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
