import React                    from 'react';
import MuiPagination            from '@material-ui/lab/Pagination';
import Select                   from '@material-ui/core/Select';
import Input                    from '@material-ui/core/Input';
import Grid                     from '@material-ui/core/Grid';
import MenuItem                 from '@material-ui/core/MenuItem';
import Typography               from '@material-ui/core/Typography';

import utility                  from 'common/utility';

class Pagination extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			itemPerPage : 50,
			currentPage : 1,
		};
		this.initPerPage = 20;
	}

	handleItemPerPage = (event) => {

		this.setState ({
			itemPerPage : event.target.value
		});
	};

	handlePage = (event, page) => {
		
		this.setState ({
			currentPage : page
		});
		utility.scrollToTop ();
	}

	render () {

		let data = this.props.data || [];

		if (!Array.isArray (data)) {
			return (
				<div>{data}</div>
			);
		}

		if (data.length < this.initPerPage) {
			return data;
		}

		let count      = Math.ceil (data.length / this.state.itemPerPage);
		let endIndex   = this.state.currentPage * this.state.itemPerPage;
		let startIndex = endIndex - this.state.itemPerPage;
		
		return (
			<div id = "scroll-top">
				{data.slice (startIndex, endIndex)}
				<Grid container alignItems = 'center' justify = 'center' className = 'mt-12'>
					<Typography variant = 'body2' className = 'mr-9'> ページごとの行数： </Typography>
					<Select value = {this.state.itemPerPage} onChange = {this.handleItemPerPage} input = {<Input disableUnderline = {true}/>}>
						<MenuItem value = {20}> 20 </MenuItem>
						<MenuItem value = {30}> 30 </MenuItem>
						<MenuItem value = {50}> 50 </MenuItem>
					</Select>
					<MuiPagination
						count           = {count}
						color           = 'primary'
						siblingCount    = {2}
						boundaryCount   = {2}
						shape           = 'round'
						showFirstButton = {true}
						showLastButton  = {true}
						size            = 'medium'
						page            = {this.state.currentPage}
						onChange        = {this.handlePage}
						variant         = 'outlined'
					/>
				</Grid>
			</div>
		);
	}
}

export default Pagination;
