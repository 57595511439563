export const app = {
	
	parentGrid : {
		height    : '100%',
		width     : '100%',
		position  : 'absolute',
		maxHeight : '100%',
		maxWidth  : '100%',
		overflow  : 'hidden auto'
	}
};
