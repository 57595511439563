const axios      = require ('axios');
const {hostname} = require ('./config');

const xhr = {};

xhr.CancelToken = axios.CancelToken;

const defaultOptions = {
	timeout      : 60000,
	responseType : 'json',
	headers      : { 'Content-Type' : 'application/json'},
	baseURL      : hostname
};

const _axios = axios.create (defaultOptions);

xhr.get = function (url, options) {

	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		const bearer_token = localStorage.getItem ('token');
		try {
			let _config = {
				url              : url,
				method           : 'get',
				headers          : { ...defaultOptions.headers, 'Authorization' : `Bearer ${bearer_token}`},
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

xhr.download = function (url, options) {

	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		const bearer_token = localStorage.getItem ('token');
		try {
			let _config = {
				url              : url,
				method           : 'get',
				headers          : { ...defaultOptions.headers, 'Authorization' : `Bearer ${bearer_token}`},
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response);
		}
		catch (err) {
			reject (err);
		}
	});
};

xhr.post = function (url, postData, options) {

	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		const bearer_token = localStorage.getItem ('token');
		try {
			let _config = {
				url              : url,
				method           : 'post',
				headers          : { ...defaultOptions.headers, 'Authorization' : `Bearer ${bearer_token}`},
				data             : postData,
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

xhr.delete = function (url, options) {

	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		const bearer_token = localStorage.getItem ('token');
		try {
			let _config = {
				url              : url,
				method           : 'delete',
				headers          : { ...defaultOptions.headers, 'Authorization' : `Bearer ${bearer_token}`},
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

xhr.put = function (url, putData, options) {

	options = options || {};

	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		const bearer_token = localStorage.getItem ('token');
		try {
			let _config = {
				url              : url,
				method           : 'put',
				data             : putData,
				headers          : { ...defaultOptions.headers, 'Authorization' : `Bearer ${bearer_token}`},
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

xhr.putform = function (url, postData, options) {

	options = options || {};
	// eslint-disable-next-line
	return new Promise (async (resolve, reject) => {

		const bearer_token = localStorage.getItem ('token');
		try {
			let _config = {
				url              : url,
				method           : 'put',
				headers          : {'Content-Type': `multipart/form-data`, 'Authorization' : `Bearer ${bearer_token}`},
				data             : postData,
				...options
			};
		
			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

export default xhr;
