import React                            from 'react';
import { Text, View, StyleSheet, Font}  from '@react-pdf/renderer';
import Title                            from '../Title';

const styles = StyleSheet.create({
	container: {
		flexShrink : 1
	},
	text : {
		fontFamily: 'Lato',
		fontSize: 11,
		wordBreak : 'break-word',
		display: 'flex',
		flexDirection:'row',
		flexWrap: 'wrap',
		width : '100%'
	},
});

export default ({ studentData }) => {

	Font.registerHyphenationCallback(word => {
		const middle = Math.floor(word.length / 2);
		const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];

		return parts;
	});

	let customField = studentData.custom_fields;
	let 研究テーマ = customField.find (c => c.fields["研究テーマ"]);
	研究テーマ = 研究テーマ ? 研究テーマ.fields["研究テーマ"] : "";
	研究テーマ = 研究テーマ.trim ();


	/*HACK
	 *TO break word
	 * */
	let parts =  研究テーマ.match(/[\s\S]{1,90}/g) || [];

	return (
		<View style = {styles.container}>
			{研究テーマ ?
				<View>
					<Title> 研究テーマ(Research Theme) </Title>
					{parts.map ((p,i) => (
						<Text style = {styles.text} key ={i}> {p} </Text>
					))}
				</View>
				: null}
		</View>
	);
};
