export const styles = {
	categoryName : {
		backgroundColor : '#1b1c1dc4',
		color           : '#FFFFFF',
		padding         : '4px',
		borderRadius    : '8px',
	},

	categoryNameApplicant : {
		color           : '#FFFFFF',
		padding         : '4px',
		borderRadius    : '8px',
		backgroundColor : '#5367a0',
	}
};