const paths = {
	landing          : '/',
	login            : '/login',
	profile          : '/profile',
	resetPassword    : '/reset_password',
	checkout         : '/checkout',
	orderHistory     : '/order_history',
	interview        : '/interview',
	interviewHistory : '/interview_history',
	forgotPassword   : '/forgot',
	jobs             : '/jobs',
	japaneseClass    : '/japaneseClass',
	onboarding       : '/onboarding',
};

export default paths;
