import React    from 'react';
import Grid     from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import utility  from 'common/utility';

function SkeletonLoader (props) {
	
	const {type} = props;

	const rowsNumber = type === 'filter' ? utility.getRandomNumber (15, 30) : utility.getRandomNumber (3, 5);
	const rows = [];

	for (let i = 0; i < rowsNumber; i++) {
		rows.push (
			<Grid key = {i} className = 'mt-12 bt-12'>
				<Skeleton variant = 'rect' height = {type === 'filter' ? 50 : 250}/>
			</Grid>
		);
	}

	return (
		<Grid>
			{rows}
		</Grid>
	);
}

export default SkeletonLoader;
