import React                       from 'react';
import { Text, View, StyleSheet }  from '@react-pdf/renderer';
import Title                       from '../Title';

const styles = StyleSheet.create({
	container: {
		flexShrink : 1
	},
	text : {
		fontFamily: 'Lato',
		fontSize: 11,
		wordBreak : 'break-word',
		display: 'flex',
		flexDirection:'row',
		flexWrap: 'wrap',
		width : '100%'
	},
});

export default ({ studentData }) => {
	let customField = studentData.custom_fields;
	let その他PR = customField.find (c => c.fields["その他PR"]);
	その他PR = その他PR ? その他PR.fields["その他PR"] : "";
	その他PR = その他PR.trim ();

	let parts =  その他PR.match(/[\s\S]{1,90}/g) || [];

	return (
		<View style = {styles.container}>
			{その他PR ?
				<View>
					<Title> その他PR (Additional Comments) </Title>
					{parts.map ((p,i) => (
						<Text style = {styles.text} key ={i}> {p} </Text>
					))}
				</View>
				: null}
		</View>

	);
};
