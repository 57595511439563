import React                       from 'react';
import { Text, View, StyleSheet }  from '@react-pdf/renderer';
import Title                       from '../Title';

const styles = StyleSheet.create({
	container: {
	},
	text : {
		fontFamily: 'Lato',
		fontSize: 11,
	},
});

export default ({ studentData }) => {
	let customField = studentData.custom_fields;
	let 日本語力    = customField.find (c => c.fields["日本語力"]);
	let JLPT        = customField.find (c => c.fields["JLPT"]);
	let 英語力      = customField.find (c => c.fields["英語力"]);

	日本語力 = 日本語力 ? 日本語力.fields["日本語力"] : "";
	JLPT     = JLPT ? JLPT.fields["JLPT"] : "";
	英語力   = 英語力 ?  英語力.fields["英語力"] : "";

	return (
		<View style = {styles.container}>
			<Title> Languages </Title>
			{日本語力 ? <Text style = {styles.text}>  日本語力 : {日本語力} </Text> : null}
			{JLPT     ? <Text style = {styles.text}>  JLPT : {JLPT} </Text> : null}
			{英語力   ? <Text style = {styles.text}>  英語力 : { 英語力} </Text> : null}
		</View>
	);
};
