import React                            from 'react';
import {Link as Navlink}                from 'react-router-dom';
import swal                             from 'sweetalert';
import MuiLink                          from '@material-ui/core/Link';
import Grid                             from '@material-ui/core/Grid';
import Paper                            from '@material-ui/core/Paper';
import Button                           from '@material-ui/core/Button';
import {withSnackbar}                   from 'notistack';
import Typography                       from '@material-ui/core/Typography';
import Hidden                           from '@material-ui/core/Hidden';
import Stepper                          from '@material-ui/core/Stepper';
import Step                             from '@material-ui/core/Step';
import StepLabel                        from '@material-ui/core/StepLabel';
import CheckCircleIcon                  from '@material-ui/icons/CheckCircle';
import Tabs                             from '@material-ui/core/Tabs';
import Tab                              from '@material-ui/core/Tab';

import TopNav                           from 'components/molecules/TopNav';
import MobileNav                        from 'components/molecules/MobileNav';
import ApplicantCard                    from 'components/molecules/ApplicantCard';
import authStore                        from 'redux/authStore';
import apis                             from 'common/apis';
import path                             from 'common/path';
import Log                              from 'common/log';
import utility                          from 'common/utility';
import {errorMsg}                       from 'common/errors';
import Skeleton                         from './Skeleton';
import {light}                          from 'styles/muiTheme/colors';
import InfoModal                        from './InfoModal';

const log = Log ('Checkout', 'info');

class Checkout extends React.Component {
	
	constructor (props) {
		super (props);

		this.state = {
			mobileNav   : false,
			picProfile  : null,
			cart        : [],
			isFetched   : false,
			jobId       : null,
			applicants  : [],
			stpes       : ['検討リスト', '確認画面', '申請'],
			currentStep : 0,
			appCount    : 0,
			infoModal   : false,
			ratingError : {},
			selectedTab : null,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = async () => {

		this.authUnsub      = authStore.subscribe (this.getProfile);
		this.getProfile();
		this.getCart ();
		this.getApplicantsCount ();
	}

	componentWillUnmount = () => {

		this.authUnsub ();
	}

	handleInfoModal = () => {
		
		this.setState ({
			infoModal : !this.state.infoModal,
		});
	}

	getProfile = () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;
		let jobId      = picProfile.jobs.length ? picProfile.jobs[0].job_id : null;

		this.setState ({
			picProfile,
			jobId,
		});
	}

	getApplicants = async (applicantIds) => {

		let data =  {
			company : {
				applicants : applicantIds,
			}
		};

		this.setState ({
			loading : true,
		});

		let result;

		try {
			result = await apis.getBulkApplicants (data);
			log.info ({applicants : result}, 'get applicants ok');
		}

		catch (err) {
			log.error ({err}, 'error getting applicants');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました', {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}

		this.setState ({
			loading    : false,
			applicants : result,
		});
	}

	getApplicantsCount = async () => {
			
		let count;

		try {
			count = await apis.getApplicantCount ();
			log.info ({count}, 'applicant count');
		}
		catch (err) {
			log.error ({err}, 'error getting applicantsi count');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			return;
		}

		if (count.errors) {
			this.notify (count.errors, {variant : 'error'});
			return;
		}

		this.setState ({
			appCount : count.count,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		let applicantIds = result.cart.map (applicant => {
			return {
				id  : applicant.applicant_id,
				sgwj_event_id : applicant.sgwj_event_id,
				job_id        : applicant.job_id,
			};

		});
		this.setState ({
			cart : result.cart,
			isFetched  : true,
		});
		this.getApplicants (applicantIds);
	}

	submitStar = async (star, applicantId, jobId) => {

		let applicantIndex = this.state.cart.findIndex (a => a.applicant_id === applicantId && a.job_id === jobId);
		let applicant = this.state.cart[applicantIndex];
		if (applicantIndex < 0) {
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}

		let __applicants     = [...this.state.applicants];

		let __applicant = __applicants[applicantIndex];
		__applicant = {
			...__applicant,
			rating : star,
		};

		__applicants[applicantIndex] = __applicant;
		let __ratingError = this.state.ratingError;
		if (this.state.ratingError[applicantIndex]) {
			delete __ratingError[applicantIndex];
		}

		this.setState ({
			applicants : __applicants,
			ratingError : __ratingError
		});

		let data = {
			shortlisted_applicant : {
				applicant_id   : applicantId,
				job_id        : jobId,
				sgwj_event_id : applicant.sgwj_event_id,
				rating        : star
			}
		};

		let result;
		try {
			result = await apis.rateApplicant (data);
		}
		catch (err) {
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			log.error ({err}, 'error submiting rating of applicant');
			this.notify ('予期しないエラーが発生しました', {variant : 'error'});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		if (result.message) {
			this.notify (result.message, {variant : 'success'});
		}
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	onConfirm = () => {

		this.setState ({
			currentStep : 1,
		});
	}

	onCheckout = async () => {
		let ratingError = this.state.ratingError;
		this.state.applicants.forEach ((applicant, index) => {
			if (!applicant.rating) {
				ratingError[index] = true;
			}
		});

		if (Object.keys(ratingError).length) {
			this.setState ({
				ratingError,
			});
			this.notify ('ニーズ合致度は必須項目です。', {variant : 'error'});
			return;
		}

		swal ({
			text : 'ASIA to JAPANに申請します',
			icon : 'warning',
			buttons : true,
		}).then (async(onOk) => {
			if(onOk) {
				let result;
				try {
					result = await apis.checkout ();
					log.info ({ready : result}, 'imready okk');
				}
				catch (err) {
					log.error ({err}, 'error in imready');
					if (err.response && err.response.status === 401) {
						this.notify (errorMsg.logoutMsg, {variant : 'error'});
						utility.redirectLogin ();
						return;
					}
					if (err.response && err.response.data?.errors) {
						this.notify(err.response.data?.errors, { variant: 'error' });
						return;
					}
					this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
					return;
				}

				if (result.errors) {
					this.notify (result.errors, {variant : 'error'});
					return;
				}
				this.setState ({
					currentStep : 2,
					cart        : []
				});
				return;
			}
		});
	}

	removeFromCart = async (applicantId, jobId) => {

		let data = {
			applicant_id   : applicantId,
			company_pic_id : this.state.picProfile.id,
			job_id         : jobId,
		};

		let result;
		try {
			result = await apis.removeFromCart (data);
		}
		catch (err) {
			log.error ({err}, 'error adding to cart');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('選出した学生を削除できません、もう一度試してください。', {variant : 'error'});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		log.info ({result}, 'removed to cart ok');
		let _cart = this.state.cart;
		let __cart = [];

		for (let i = 0; i < _cart.length; i++) {
			if (_cart[i].applicant_id === data.applicant_id && _cart[i].job_id === data.job_id) {
				continue;
			}
			__cart.push (_cart[i]);
		}

		this.setState ({
			cart : __cart,
		});
		this.notify (result.message, {variant : 'success'});
		this.getApplicantsCount ();
	}

	handleTabChange = (event, newValue) => {
		this.setState ({
			selectedTab : newValue
		});
	}

	handleDownloadCsv = () => {
		utility.downloadApplicants(this.state.applicants, 'CHECKOUT');
	}

	renderReviewStep = () => {
		
		let cartApplicant = [];
		let __cart = this.state.cart;
		let __jobs = {};

		for (let i = 0; i < __cart.length; i++) {
			let __applicant = this.state.applicants.find (a => a.id === __cart[i].applicant_id);
			if (!__applicant) {
				continue;
			}
			__applicant = {
				...__applicant,
				job_name : __cart[i].job_name,
				job_id   : __cart[i].job_id,
			};
			if (!__jobs[__cart[i].job_id]) {
				__jobs[__cart[i].job_id] = {
					name : __cart[i].job_name,
					jobId : __cart[i].job_id
				};
			}
			cartApplicant.push (__applicant);
		}

		if (!this.state.isFetched) {
			return (
				<Skeleton />
			);
		}

		let __jobsIds = Object.keys (__jobs);

		return (
			<Grid>
				{__cart.length ?
					<Paper variant = 'outlined' className = 'p-12 mt-24'>
						<Typography>
							<span style = {{color : '#C33934'}}>  採用成功の秘訣は </span>
							<MuiLink style = {{cursor : 'pointer'}} onClick = {this.handleInfoModal}> こちら </MuiLink>
						</Typography>
					</Paper>
					:
					<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}>検討リストは０人です、ホームより選出をおねがいします。</Typography>}
				<Paper className='pl-12 pr-12 pt-4 pb-4 mt-12' variant='outlined'>
					<Tabs value={this.state.selectedTab || __jobsIds[0]} textColor='inherit' variant="scrollable" scrollButtons="auto" onChange = {this.handleTabChange }>
						{__jobsIds.map(job => (
							<Tab label={__jobs[job].name} key={job} value={job}/>
						))}
					</Tabs>
				</Paper>
				{cartApplicant.filter(_app => _app.job_id.toString() === (this.state.selectedTab || __jobsIds[0])).map ((applicant, index) => (
					<ApplicantCard
						jobId          = {this.state.jobId}
						applicant      = {applicant}
						key            = {index}
						cart           = {this.state.cart}
						removeFromCart = {this.removeFromCart}
						removeButton   = {true}
						showJobName    = {applicant.job_name}
						hidePersonal   = {true}
						page           = "CHECKOUT"
					/>
				))}
			</Grid>
		);
	}

	renderReviewStepAction = () => {
		
		let __picProfile = this.state.picProfile;
		return (
			<Grid container justify = 'center'>
				{__picProfile && __picProfile.discount_note ?
					<Grid className = 'm-24 p-12' style = {{color : light.error.main, border : `1px solid ${light.error.main}`, borderRadius : '20px'}}>
						<Typography variant = 'h6'> {__picProfile.discount_note} </Typography>
					</Grid>
					: null}
				<Button color = 'primary' variant = 'contained' fullWidth component = {Navlink} to = {path.landing}>
					追加で学生を探す
				</Button>
				<Button className = 'mt-24' color = 'primary' variant = 'contained' fullWidth component = {Navlink} to = {{pathname : path.landing, state : {isBunkei : true}}}>
					 文系学生を探す
				</Button>
				{this.state.cart.length ?
					<Button color = 'primary' variant = 'outlined' className = 'mt-24' fullWidth onClick = {this.onConfirm}>
						確認画面へ進む
					</Button> : null}
			</Grid>
		);
	}

	renderConfirmationStep = () => {
	
		let cartApplicant = [];
		let __cart       = this.state.cart;
		let __picProfile = this.state.picProfile;

		for (let i = 0; i < __cart.length; i++) {
			let __applicant = this.state.applicants[i];
			if (!__applicant) {
				continue;
			}
			cartApplicant.push ({...__applicant, job_id : __cart[i].job_id});
		}

		return (
			<Grid>
				<Paper elevation = {2} className = 'p-12 mt-24' style = {{color : '#5b5b5b'}} variant = 'outlined'>
					<Typography variant = 'subtitle2'> 申請する前に： </Typography>
					<Typography variant = 'body2'>
						マッチング精度向上のために、選抜頂いた学生と貴社の採用ニーズとの合致度　(1＝低、5＝高）をご記入くださいませ。
						ご不明点ございましたら、弊社の担当営業にご連絡いただければと存じます
					</Typography>
				</Paper>
				{__picProfile && __picProfile.discount_note ?
					<Grid className = 'm-24 p-12' style = {{color : light.error.main, border : `1px solid ${light.error.main}`, borderRadius : '20px'}}>
				 		<Typography variant = 'h6'> {__picProfile.discount_note} </Typography>
					</Grid>
					: null}
				{cartApplicant.map ((applicant, index) => (
					<ApplicantCard
						applicant      = {applicant}
						key            = {`${applicant.id}${applicant.job_id}`}
						cart           = {this.state.cart}
						removeFromCart = {this.removeFromCart}
						showStars      = {true}
						onSubmitStar   = {this.submitStar}
						hidePersonal   = {true}
						page           = "CHECKOUT"
						ratingError    = {this.state.ratingError}
						cartIndex      = {index}
					/>
				))}
			</Grid>
		);
	}

	renderConfirmationStepAction = () => {
		
		return (
			<Grid container justify = 'center'>
				<Button color = 'primary' variant = 'contained' fullWidth onClick = {this.onCheckout}>
					ASIA to JAPANに申請
				</Button>
			</Grid>
		);
	}

	renderFinalStep = () => {
	
		return (
			<Grid>
				<Paper elevation = {2} className = 'p-12 mt-24' style = {{color : '#5b5b5b', textAlign : 'center'}} variant = 'outlined'>
					<CheckCircleIcon style = {{height : '60px', width : '60px', color : light.success.main}}/>
					<Typography variant = 'h4' className = 'mb-12' style = {{color : light.success.main}}> 申請を承りました。</Typography>
					<Grid container justify = 'center'>
						<Button color = 'primary' variant = 'outlined' fullWidth className = 'mt-24' component = {Navlink} to = {path.landing}>
					追加で学生を選ぶ
						</Button>
					</Grid>
				</Paper>
			</Grid>
		);
	}

	renderSteps = () => {
		
		let stepNo = this.state.currentStep;
		
		switch (stepNo) {
			case 0 :
				return this.renderReviewStep ();
			case 1 :
				return this.renderConfirmationStep ();
			case 2 :
				return this.renderFinalStep ();
			default :
				return;
		}
	}

	renderStepAction = () => {
		
		let stepNo = this.state.currentStep;
		
		switch (stepNo) {
			case 0 :
				return (
					<Paper variant = 'outlined' elevation = {4} className = 'p-24 mt-24'>
						{this.renderReviewStepAction ()}
					</Paper>);
			case 1 :
				return (
					<Paper variant = 'outlined' elevation = {4} className = 'p-24 mt-24'>
						{this.renderConfirmationStepAction ()}
					</Paper>);
			default :
				return;
		}
	}

	render () {
		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing={3} alignItems='center'>
						<Grid item md = {8} lg = {9} xl = {9}>
							<Stepper activeStep = {this.state.currentStep}>
								{this.state.stpes.map (label => (
									<Step key = {label}>
										<StepLabel> {label} </StepLabel>
									</Step>
								))}
							</Stepper>
						</Grid>
						<Grid item md = {4} lg = {3} xl = {3}>
							<Paper variant = 'outlined' elevation = {4} className = 'p-16'>
								<Button disabled = {!this.state.applicants?.length} color = 'primary' variant = 'contained' fullWidth onClick = {this.handleDownloadCsv}> 学生情報をダウンロード </Button>
							</Paper>
						</Grid>
					</Grid>
					<Grid container spacing = {3}>
						<Grid item xs = {12} sm = {12} md = {8} lg = {9} xl = {9}>
							{this.renderSteps ()}
						</Grid>
						<Grid item xs = {12} sm = {12} md = {4} lg = {3} xl = {3}>
							{this.renderStepAction ()}
						</Grid>
					</Grid>
				</Grid>
				{this.state.infoModal ? <InfoModal onClose = {this.handleInfoModal} /> : null}
			</Grid>
		);
	}
}

export default withSnackbar(Checkout);
