export default function search (applicants, search) {
	
	if (!Array.isArray (applicants)){
		return;
	}

	let searched = [];
	for (let i = 0; i < applicants.length; i++) {
		if (applicants[i].id.toString().includes(search)) {
			searched.push (applicants[i]);
		}
	}
	return searched;
}
