import {light} from 'styles/muiTheme/colors';

export const styles = {
	
	avatar : {
		width           : '30px',
		height          : '30px',
		fontSize        : '0.875em',
		backgroundColor : light.primary.main,
	},

	toolbar : {
		justifyContent : 'flex-end'
	},

	navLink : {
		color       : '#000',
		fontSize    : '1rem',
		marginRight : '40px',
	},

	popper : {
		zIndex : 1000
	},

	cartIcon : {
		height : 35,
		width  : 35,
		cursor : 'pointer',
		color  : '#288bfa'
	},

	navbar : {
		boxShadow : 'none',
		borderBottom : '1px solid #ccc',
	},
	nameBtn : {
		width : 'calc(100% - 116px)'
	}
};
