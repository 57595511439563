import React   from 'react';
import Select  from 'react-select';
import {light} from 'styles/muiTheme/colors';

function AutoSelect (props) {

	const {className, options, value, disabled, loading, onChange, name, searchable, placeholder, error} = props;

	const customStyles = {
		indicatorsContainer: (provided) => ({
			...provided,
			width : 40
		}),
		control : (provided) => ({
			...provided,
			border : `1px solid ${error ? light.error.main : '#ccc'}`,
		}),
		menuPortal : (provided) => ({
			...provided,
			zIndex: 9999
		}),
	};

	return (
		<Select
			className    = {className}
			styles       = {customStyles}
			isDisabled   = {disabled}
			isLoading    = {loading}
			value        = {value}
			isSearchable = {searchable}
			placeholder  = {placeholder}
			menuPortalTarget={document.body}
			name         = {name}
			options      = {options}
			onChange     = { (value, action) => onChange (action.name, value)}
		/>
	);
}

export default AutoSelect;