import React              from 'react';
import ReactDOM           from 'react-dom';
import { BrowserRouter }  from 'react-router-dom';
import App                from './App';
import reportWebVitals    from './reportWebVitals';

import './styles/fonts/style.css';
import './styles/global.css';

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
