import React         from 'react';
import {NavLink}     from 'react-router-dom';
import Grid          from '@material-ui/core/Grid';
import Link          from '@material-ui/core/Link';
import Paper         from '@material-ui/core/Paper';

import {navigations} from 'common/config';
import path          from 'common/path';
import authStore     from 'redux/authStore';
import {styles}      from './style';

function MobileNav () {

	const [routes, setRoutes]  = React.useState ([]);

	React.useEffect (() => {
		getAuthInfo ();
	}, []);

	React.useEffect (() => {
		return authUnsub;
	}, []);

	const getAuthInfo = () => {
		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		let isOnboarded = picProfile && picProfile['is_onboarded'];
		let __navigations = [...navigations];

		if (isOnboarded) {
			setRoutes(__navigations);
			return;
		}
		setRoutes (__navigations.filter(nav => nav.link !== path.onboarding));
	};

	const authUnsub = authStore.subscribe (getAuthInfo);

	return (
		<Grid>
			<Paper variant = 'outlined' style = {styles.paper}>
				{routes.map ((nav, index) => (
					<Grid className = 'pt-8 pb-8' style = {styles.grid} key = {index}>
						<Link component = {NavLink} exact = {true} to = {nav.link} style = {styles.navLink} id = 'navlink'> {nav.name} </Link>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default MobileNav;
