import React                       from 'react';
import moment                      from 'moment';
import { Text, View, StyleSheet }  from '@react-pdf/renderer';
import Title                       from '../Title';

const styles = StyleSheet.create({
	container: {
	},
	text : {
		fontFamily: 'Lato',
		fontSize: 11,
	},
});

export default ({ studentData }) => {
	let universities = studentData.universities;

	return (
		<View style = {styles.container}>
			<Title> Education </Title>
			{universities?.map(uni => (
				<View style = {{borderBottomWidth : '1px', borderBottomStyle : 'solid', borderBottomColor : '#ccc', paddingTop : 4, paddingBottom : 4}}>
					<Text style = {styles.text}> 大学</Text>
					<Text style = {styles.text}> {uni.university_name}</Text>
					<Text style = {{...styles.text, fontSize : 10}}>
						{uni.start_month ? moment(uni.start_month, 'M').format('MMMM') : 'NA'} {uni.start_year || 'NA'}
							&nbsp;-&nbsp;
						{uni.graduated_month ? moment(uni.graduated_month, 'M').format('MMMM') : 'NA'} {uni.graduated_year || 'NA'}
					</Text>
					{uni.education_background ? <Text style={styles.text}>  学歴 : {uni.education_background} </Text> : null}
					{uni.faculty ? <Text style={styles.text}>  学部 : {uni.faculty} </Text> : null}
					{uni.department ? <Text style={styles.text}>  学科 : {uni.department} </Text> : null}
				</View>
			))}
		</View>
	);
};
