import React                      from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title                      from '../Title';

const styles = StyleSheet.create({
	container: {
	},
	text: {
		fontFamily: 'Lato',
		fontSize: 11,
	},
	textBold: {
		fontFamily: 'Lato Bold',
		fontSize: 11,
	},
});

export default (props) => {
	const { studentData } = props;
	let customField = studentData.custom_fields;

	let 国   = studentData.country;
	let 誕生日 = studentData.date_of_birth;
	let 性別 = customField.find (c => c.fields["性別"]);
	let 年齢 = studentData.age;
	性別     = 性別 ? 性別.fields['性別'] : "";

	return(
		<View style={styles.container}>
			<Title>Personal Details</Title>
			{国   ? <Text style={styles.text}>国籍・地域: {国} </Text> : null}
			{性別 ? <Text style={styles.text}>性別: {性別} </Text> : null}
			{誕生日 ? <Text style={styles.text}>誕生日: {誕生日} </Text> : null}
			{年齢 ? <Text style={styles.text}>年齢: {年齢} </Text> : null}
		</View>
	);
};
