import React                        from 'react';
import { withSnackbar }             from 'notistack';
import Grid                         from '@material-ui/core/Grid';
import Hidden                       from '@material-ui/core/Hidden';
        
import TopNav                       from 'components/molecules/TopNav';
import MobileNav                    from 'components/molecules/MobileNav';
import CompanyNotice                from './CompanyNotice';
import ApplicantNotice              from './ApplicantNotice';
import apis                         from 'common/apis';
import {errorMsg}                   from 'common/errors';
import authStore, {picProfileInfo}  from 'redux/authStore';
import utility                      from 'common/utility';
import Log                          from 'common/log';

const log = Log('Onboarding', 'info');

class Onboarding extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav           : false,
			loading             : false,
			picProfile          : null,
			cart                : [],
			companyData         : [],
			onboardingApplicant : [],
			companyEditables    : {},
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		this.getProfile();
		this.getCart();
		this.getOnboardingCompanyInfo();
		this.getOnboardedApplicant ();
		this.getEditCompanyData ();
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');
			this.setState ({
				picProfile,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		this.setState ({
			picProfile,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getOnboardedApplicant = async() => {
		let result;
		try {
			result = await apis.getOnboardedApplicant();
			log.info ({result}, 'onboarded applicant get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding applicant data');
			if (err.response?.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			onboardingApplicant : result
		});
	}

	getEditCompanyData = async() => {
		let result;
		try {
			result = await apis.getOnboardingCompanyEditProfile();
			log.info ({result}, 'onoarding company edit data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding edit data');
			if (err.response?.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			companyEditables : result.custom_fields,
		});
	}

	getEditApplicantData = async() => {
		try {
			let result = await apis.getOnboardingEditProfile ();
			log.info ({result}, 'onoarding applicant edit data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding applicant edit data');
			if (err.response?.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
	}

	getOnboardingCompanyInfo = async() => {
		let result;
		try {
			result = await apis.getOnboardingCompanyProfile ();
			log.info ({result}, 'onoarding company info data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding edit data');
			if (err.response?.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			companyData : result.custom_fields,
		});
	}

	handleMobileNav () {
		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	render () {
		return (
			<Grid className='parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only={['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className={`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<CompanyNotice companyData = {this.state.companyData} editableFields = {this.state.companyEditables}/>
					<Grid>
						{this.state.onboardingApplicant.map (applicant => (
							<Grid key = {applicant.id} className = 'mt-24'>
								<ApplicantNotice
									applicantData        = {applicant.custom_fields}
									applicantId          = {applicant.id}
									applicantDocument    = {applicant.document_fields}
									applicantDownloadDoc = {applicant.downloadable_documents}
									applicantName        = {applicant.name}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar (Onboarding);