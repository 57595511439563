import React                            from 'react';
import moment                           from 'moment';
import ReactHtmlParser                  from 'react-html-parser';
import { pdf}                           from "@react-pdf/renderer";
import {Link as Navlink}                from 'react-router-dom';
import {withSnackbar}                   from 'notistack';
import { saveAs }                       from 'file-saver';
import Grid                             from '@material-ui/core/Grid';
import List                             from '@material-ui/core/List';
import ListItem                         from '@material-ui/core/ListItem';
import ListItemText                     from '@material-ui/core/ListItemText';
import Dialog                           from '@material-ui/core/Dialog';
import DialogTitle                      from '@material-ui/core/DialogTitle';
import Card                             from '@material-ui/core/Card';
import Chip                             from '@material-ui/core/Chip';
import Divider                          from '@material-ui/core/Divider';
import Typography                       from '@material-ui/core/Typography';
import Hidden                           from '@material-ui/core/Hidden';
import Button                           from '@material-ui/core/Button';
import Paper                            from '@material-ui/core/Paper';
import DownloadIcon                     from '@material-ui/icons/GetApp';
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';
import {faGraduationCap }               from '@fortawesome/free-solid-svg-icons';

import Loader                           from 'components/atoms/Loader';
import TopNav                           from 'components/molecules/TopNav';
import MobileNav                        from 'components/molecules/MobileNav';
import ButtonMenu                       from 'components/atoms/ButtonMenu';
import Logo                             from 'assets/atoj-logo.png';
import apis                             from 'common/apis';
import Log                              from 'common/log';
import path                             from 'common/path';
import Resume                           from 'pages/Pdf';
import utility                          from 'common/utility';
import {errorMsg}                       from 'common/errors';
import authStore, {picProfileInfo}      from 'redux/authStore';

const log = Log ('ApplicantProfile', 'info');

class ApplicantProfile extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav   : false,
			loading     : false,
			picProfile  : null,
			cart        : [],
			addLoading  : false,
			jobId       : null,
			profile     : null,
			suggestion  : [],
			applicantId : '',
			cartBtnLoading : false,
			jobModal    : false,
			blob        : null,
			resumeLink  : null,
			isInterview : false,
			downloadDocList : null,
			pdfLoading  : false,
			randomSuggestion : [],
			page : ""
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {

		let applicantId = this.props.match.params.id;
		let urlParams = new URLSearchParams(window.location.search);
		let interview = urlParams.get ('interview');
		let page      = urlParams.get ('page');
		this.getProfile ();
		this.getCart    ();
		this.getApplicantProfile (applicantId, interview);
		this.setState ({
			applicantId,
			isInterview : interview,
			page,
		});
	}

	openJobModal = () => {

		let __picProfile = this.state.picProfile;
		if (__picProfile && __picProfile.jobs && __picProfile.jobs.length === 1) {
			this.addToCart (__picProfile.jobs[0].job_id);
			return;
		}
		
		this.setState ({
			jobModal : true,
		});
	}

	closeJobModal = () => {
		
		this.setState ({
			jobModal : false,
		});
	}

	handleMobileNav = () => {
		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs.length ?  picProfile.jobs[0].job_id : null;
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs.length ?  picProfile.jobs[0].job_id : null;
		this.setState ({
			picProfile,
			jobId,
		});
		/**
		 * Commenting random suggestion code for  now it is not used
		 */
		//let applicantId = this.props.match.params.id;
		//this.getApplicants (jobId, applicantId);
	}

	getDownloadList = async (applicantId) => {
		let data = {
			applicant : {
				applicant_id : applicantId,
			}
		};
		let result;
		try {
			result = await apis.getDownloadableDoc (data);
			log.info ({donloadDoc : result}, 'download doc get list ok');
		}
		catch (err) {
			log.error ({err}, 'error getting download doc list');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			downloadDocList : result.downloadable_documents,
		});
	}

	getApplicantProfile = async (applicantId, interview) => {
		
		let profile;

		let data = {
			applicant : {
				id : applicantId,
			}
		};

		try {
			if (interview) {
				profile = await apis.getInterviewProfile (data);
			}
			else {
				profile = await apis.getApplicantProfile (data);
			}
		}
		catch (err) {
			log.error ({err}, 'error getting applicant profile');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		if (profile.errors) {
			this.notify (profile.errors, {variant : 'error'});
			return;
		}
		log.info ({profile}, 'get applicant profile ok');
		this.setState ({
			profile,
		});
		this.getDownloadList (applicantId);
	}

	getApplicants = async (jobId, applicantId) => {

		let data = {
			applicant : {
				job_id  : jobId,
				profile_id : applicantId,
			}
		};
		let result;

		try {
			result = await apis.getSidebarApplicants (data);
			log.info ({applicants : result}, 'get applicants ok');
		}

		catch (err) {
			log.error ({err}, 'error getting applicants');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました', {variant : 'error'});
			return;
		}

		if (!result.length) {
			return;
		}

		this.setState ({
			randomSuggestion : result,
		});
	}

	addToCart = async (jobId) => {

		let data = {
			applicant_id   : Number(this.state.applicantId),
			company_pic_id : this.state.picProfile.id,
			job_id         : jobId,
		};

		this.setState ({
			cartBtnLoading : true,
			jobModal       : false,
		});

		let result;
		try {
			result = await apis.addToCart (data);
		}
		catch (err) {
			log.error ({err}, 'error adding to cart');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('学生の追加ができません、もう一度試してください。', {variant : 'error'});
			this.setState ({
				cartBtnLoading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				cartBtnLoading : false,
			});
			return;
		}

		log.info ({result}, 'add to cart ok');
		let _cart = this.state.cart;
		_cart.push (data);

		this.setState ({
			cart : _cart,
			cartBtnLoading : false,
		});
		this.notify (result.message, {variant : 'success'});
	}

	removeFromCart = async (jobId) => {

		let applicantId = this.state.applicantId;
		let applicant = this.state.cart.find (a => a.applicant_id === Number(applicantId));
		if (!applicant) {
			return; //IMPOSSIBLE CASE;
		}

		/*TODO
		 *  Needs to handle case when same applicant is added in the cart for two job id, or added by more than one company pic;
		 * */

		this.setState ({
			cartBtnLoading : true,
		});

		let data = {
			applicant_id   : Number(applicantId),
			company_pic_id : applicant.company_pic_id,
			job_id         : jobId,
		};

		let result;
		try {
			result = await apis.removeFromCart (data);
		}
		catch (err) {
			log.error ({err}, 'error adding to cart');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				cartBtnLoading : false,
			});
			this.notify ('選出した学生を削除できません、もう一度試してください。', {variant : 'error'});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				cartBtnLoading : false,
			});
			return;
		}

		log.info ({result}, 'removed to cart ok');
		let _cart = this.state.cart;
		let __cart = [];

		for (let i = 0; i < _cart.length; i++) {
			if (_cart[i].applicant_id === data.applicant_id && _cart[i].job_id === data.job_id) {
				continue;
			}
			__cart.push (_cart[i]);
		}

		this.setState ({
			cart : __cart,
		});
		this.notify (result.message, {variant : 'success'});
	}

	renderCustomFields = (customFields) => {
		
		if (!customFields) {
			return;
		}
		let categories = [];
		let cards = [];
		for (let i = 0; i < customFields.length; i++) {
			let __fields = customFields[i].fields;
			let fields = [];
			for (let j = 0; j < Object.keys (__fields).length; j++) {
				let value = __fields[Object.keys(__fields)[j]];
				if (Array.isArray(value)) {
					if (!value.length) {
						continue;
					}
					fields.push (
						<Grid container spacing = {2} alignItems = 'flex-start' key  = {j}>
							<Grid item sm = {3}>
								<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> {Object.keys(__fields)[j]}</Typography>
							</Grid>
							<Grid item sm = {9}>
								{value.map ((item, index) => (
									<Chip label = {item} key = {index} className = 'mr-12 mb-8'/>
								))}
							</Grid>
						</Grid>
					);
					continue;
				}
				if (!value) {
					continue;
				}
				fields.push (
					<Grid container spacing = {2} alignItems = 'flex-start' key = {j}>
						<Grid item sm = {3}>
							<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> {Object.keys(__fields)[j]}</Typography>
						</Grid>
						<Grid item sm = {9}>
							<Typography variant = 'body1' style = {{whiteSpace : 'pre-wrap', wordBreak : 'break-word'}}> {value.trim()} </Typography>
							<Divider className = 'mt-4 mb-4'/>
						</Grid>
					</Grid>
				);
			}
			if (!fields.length) {
				continue;
			}
			
			if (customFields[i].category_name === "ASIAtoJAPAN面談項目") {
				categories.push (
					<Card className = 'mt-12' key = {i}>
						<Grid container spacing = {2} className= 'p-12 pb-8' style = {{borderLeft : '12px solid #03a9f5'}} alignItems = 'flex-start'>
							<Typography variant = 'h6' style = {{fontSize : '17px'}}>
								<FontAwesomeIcon className = 'mr-8' icon = {faGraduationCap}/>
								{customFields[i].category_name}
							</Typography>
						</Grid>
						<Divider className= 'mt-8'/>
						<Grid className = 'p-12'>
							{fields}
						</Grid>
					</Card>
				);
				continue;
			}

			cards.push (
				<Card className = 'mt-12' key = {i}>
					<Grid container spacing = {2} className= 'p-12 pb-8' style = {{borderLeft : '12px solid #03a9f5'}} alignItems = 'flex-start'>
						<Typography variant = 'h6' style = {{fontSize : '17px'}}>
							<FontAwesomeIcon className = 'mr-8' icon = {faGraduationCap}/>
							{customFields[i].category_name}
						</Typography>
					</Grid>
					<Divider className= 'mt-8'/>
					<Grid className = 'p-12'>
						{fields}
					</Grid>
				</Card>
			);
		}
		return {cards, categories};
	}

	renderCartButton = () => {

		if (!this.state.jobId) {
			return;
		}

		let menus = [];

		let applicantId = this.props.match.params.id;

		let allJobs = this.state.picProfile.jobs;
		for (let i = 0; i < allJobs.length; i++) {
			let isAdded = this.state.cart.filter (a => a.applicant_id === Number(applicantId));
			let isAddedJob = isAdded.find (c => c.job_id === allJobs[i].job_id);
			if (isAddedJob) {
				menus.push ({
					action : this.removeFromCart,
					name   : `検討リストから削除 ${allJobs[i].job_title}`,
					...allJobs[i],
				});
				continue;
			}
			menus.push ({
				action : this.addToCart,
				name   : `検討リスト追加 ${allJobs[i].job_title}`,
				...allJobs[i],
			});
		}

		/**
		 * if there is only one option directly add or remove to cart
		 */
		if (menus.length === 1) {
			return (
				<Button variant='outlined' onClick={() => menus[0].action(menus[0].job_id)} className='mt-12' fullWidth> {menus[0].name.split(' ')[0]} </Button>
			);
		}

		return (
			<ButtonMenu btn = "検討リスト" menu = {menus}/>
		);

		/*
		if (isAdded) {
			return (
				<Button  color = 'primary' fullWidth onClick = {this.removeFromCart} className = 'mt-12'>
					{this.state.cartBtnLoading ? <Loader color = 'primary'/> : '検討リストから削除'}
				</Button>
			);
		}
 
		return (
			<Button variant = 'outlined' color = 'primary' fullWidth onClick = {this.openJobModal} className = 'mt-12'>
				<StarOutlineIcon className = 'mr-12'/>
				{this.state.cartBtnLoading ? <Loader color = 'primary'/> : '検討リスト追加'}
			</Button>
		);
		*/
	}

	renderPrVideo = (pr_video) => {

		let vurl = pr_video;
		let vid = vurl.split ('/');
		vid = vid[vid.length - 1];
		let vsrc = pr_video.includes ('vimeo.com') ? `https://player.vimeo.com/video/${vid}` : pr_video;
		return (
			<div className='embed-container'>
				<iframe src={vsrc} frameBorder='0' allowFullScreen></iframe>
			</div>
		);
	}

	getVideoThumbnail = async (studentData) => {
		let vurl = studentData.pr_video;
		if (!vurl) {
			return;
		}
		let vid = vurl.split ('/');
		vid = vid[vid.length - 1];

		let thumbnailUrl;
		try {
			thumbnailUrl = await apis.getVideoThumbnail(vid);
			log.info ({thumbnailUrl}, 'thumbnail get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting video thumbnail');
			return;
		}
		let turl =  thumbnailUrl.data ? thumbnailUrl.data[0]?.sizes[5]?.link : thumbnailUrl.pictures?.sizes[5]?.link;
		return turl;
	};

	getApplicantPhoto = async(applicantId) => {
		if (!applicantId) {
			return;
		}
		try {
			let result =  await apis.getApplicantPhoto({applicant : {applicant_id : applicantId}});
			let base64data =  utility.getImgSrc (result.photograph);
			return base64data;
		}
		catch (err) {
			log.error ({err}, 'error getting applicant profile');
			return;
		}
	}

	createPDF = async(results) => {
		let thumbnail = await this.getVideoThumbnail(results);
		results.thumbnail = thumbnail;
		let photo =  await this.getApplicantPhoto(results.id);
		if (photo) {
			results.photograph = photo;
		}

		await pdf(<Resume studentData={results} isInterview = {this.state.isInterview}/>)
			.toBlob()
		// eslint-disable-next-line no-loop-func
			.then(blobProp => saveAs(blobProp, results.id));
	}

	downloadFile = async (ev, document_id) => {
		let applicantId = this.state.applicantId;
		let data = {
			applicant : {
				applicant_id     : applicantId,
				document_id : document_id,
			}
		};
		let link;
		try {
			link =  await apis.getDownloadLink (data);
		}
		catch (err) {
			log.error ({err}, 'error in downloading file');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		if (!link.download_url) {
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		return window.open (link.download_url);
	}

	renderDownloadDoc = () => {
		
		if (!this.state.downloadDocList) {
			return;
		}

		let __downloadDocs = this.state.downloadDocList;
		let allDocs = Object.keys (__downloadDocs);
		if (!allDocs.length) {
			return;
		}
		
		let buttons = allDocs.map ((doc, index) => (
			<Grid key = {index}>
				<Button variant = 'contained' color = 'primary' fullWidth  className = 'mt-12' onClick = {(ev) => this.downloadFile (ev, __downloadDocs[doc].document_id)}>
					<DownloadIcon />
					{doc}
				</Button>
			</Grid>
		));
		return buttons;
	}

	getAddress = (customFields) => {
		
		let add = customFields.find (c => c.fields['住所']);
		if (!add) {
			return;
		}

		return ` / ${add.fields['住所']}`;
	}

	downloadPdf = async () => {

		this.setState ({
			pdfLoading : true,
		});
		if (this.state.picProfile.participation || this.state.isInterview) {
			await this.createPDF (this.state.profile);
			this.setState ({
				pdfLoading : false,
			});
		}

		const url  = this.state.resumeLink;
		var isSafari = window.safari !== undefined;
		if (isSafari) {
			return url ?
				window.open(url, '_blank') : null;
		}
		if (window.navigator.msSaveOrOpenBlob) {
			return url ?
				window.navigator.msSaveOrOpenBlob(url, this.state.applicantId + ".pdf") :  null;}
		else {
			return url ?
				window.open(url, '_blank') : null; }
	}

	renderSideBarButton = () => {
		let sidebar = this.state.profile ? this.state.profile.sidebar_buttons : null;
		if (!sidebar) {
			return;
		}
		let buttons = [];
		for (let i = 0; i < sidebar.length; i++) {
			let name = Object.keys (sidebar[i]);
			let url = sidebar[i][name];
			if (!url) {
				continue;
			}
			buttons.push (
				<Grid key = {i}>
					<Button variant = 'contained' color = 'primary' fullWidth  className = 'mt-12' style = {{height : 'auto', textAlign : 'center'}} href = {url} target = '_blank'>
						{ReactHtmlParser(name)}
					</Button>
				</Grid>
			);
		}
		return buttons;
	}

	getRandomSuggestion = (arr) => {
		
		var shuffled = arr.sort(function(){return .5 - Math.random();});

		var selected = shuffled.slice(0,4);
		return selected;
	}

	showSuggestions = () => {
		return (
			<>
				{this.state.isInterview || this.state.page === "HISTORY" ? null :
					<Paper elevation = {2} className = 'p-12 mt-12'>
						<Typography variant = 'h5' className = 'mt-12' align = 'center'> この学生と似ている学生 </Typography>
						{this.state.randomSuggestion.map ((applicant, index) => (
							<div key = {index}>
								<Divider className = 'mt-12'/>
								<Grid className = 'mt-12'>
									{applicant.pr_video ?
										this.renderPrVideo (applicant.pr_video) :
										<div className = 'embed-container' style = {{background : '#d5d5d5'}}>
											<Grid style =  {{position : 'relative', top :'50px'}}>
												<img src = {Logo} height = {60} width = {60} style = {{display : 'block', margin : '0px auto'}}/>
												<Typography variant = 'h6' align = 'center'>
																	ビデオはありません
												</Typography>
											</Grid>
										</div>
									}
									<Grid className = 'pl-12 pr-12' container alignItems = 'flex-start'>
										<Grid item sm = {8}>
											<Typography variant = 'h6' color = 'primary'
												component = {Navlink}
												to        = {`${path.profile}/${applicant.id}`} onClick = {() => this.getApplicantProfile (applicant.id)}> {applicant.id} </Typography>
										</Grid>
										<Grid item sm = {4}>
											{/*<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}} align = 'right'> {applicant.id} </Typography>*/}
										</Grid>
									</Grid>
								</Grid>
							</div>
						))}
					</Paper>}
			</>
		);
	}
	

	render () {

		let profile = this.state.profile;

		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing = {8}>
						<Grid item xl = {1}>
						</Grid>
						{!profile ? <div>Loading ... </div> :  // Show proper loaders or skeletons
							<Grid container spacing = {4} item xl = {10}>
								<Grid item xs = {12} sm = {8} md = {9} lg = {9} xl = {9}>
									<Paper elevation = {2}>
										{profile.pr_video ?
											this.renderPrVideo (profile.pr_video)
											:
											<div className = 'embed-container' style = {{background : '#d5d5d5'}}>
												<Grid style =  {{position : 'relative', top :'250px'}}>
													<img src = {Logo} height = {60} width = {60} style = {{display : 'block', margin : '0px auto'}}/>
													<Typography variant = 'h6' align = 'center'>
														ビデオはありません
													</Typography>
												</Grid>
											</div> }
										<Grid className = 'pl-12 pr-12 pb-12'>
											<Grid container spacing = {2} alignItems = 'flex-start'>
												<Grid item sm = {5}>
													<Typography variant = 'h4' color = 'primary'> {profile.id}
														{profile.is_high_spec ?
															<span
																style={{
																	background: '#ffc107',
																	borderRadius: '6px',
																	padding: '0px 12px',
																	fontWeight: 'bold',
																	marginLeft: '24px',
																	fontSize : '14px',
																	color : '#000000de',
																	position : 'relative',
																	bottom : '3px',
																}}
															>HIGH-SPEC
															</span> : null}
													 </Typography>
												</Grid>
												<Grid item sm = {7} container justify = 'flex-end'>
													<Typography variant = 'h6' align = 'right'>
														<strong> Country/Region: </strong>
													</Typography>
													<Typography variant = 'body1' style = {{fontSize : '1rem'}} className = 'ml-8'>
														{profile.country} {this.getAddress(profile.custom_fields)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Paper>
									<Card className='mt-12'>
										<Grid container spacing={2} className='p-12 pb-8' style={{ borderLeft: '12px solid #03a9f5' }} alignItems='flex-start'>
											<Typography variant='h6' style={{ fontSize: '17px' }}>
												<FontAwesomeIcon className='mr-8' icon={faGraduationCap} />
												教育の詳細
											</Typography>
										</Grid>
										<Divider className='mt-8' />
										<Grid className='p-12'>
											{profile.universities?.map((uni, i) => (
												<Grid key={uni.id}>
													<Grid>
														<Typography variant='subtitle2' style={{ color: '#6b6b6b' }}>  大学 </Typography>
														<Typography variant='subtitle2' style={{ wordBreak: 'break-word' }}>{uni.university_name}</Typography>
														<Typography variant='caption' style={{ wordBreak: 'break-word' }}>
															{uni.start_month ? moment(uni.start_month, 'M').format('MMMM') : 'NA'} { uni.start_year || 'NA'}
															&nbsp;-&nbsp;
															{uni.graduated_month ? moment(uni.graduated_month, 'M').format('MMMM') : 'NA'} { uni.graduated_year || 'NA'}
														</Typography>
													</Grid>
													<Grid>
														<Grid container className = 'mt-8' spacing = {2}>
															<Grid item sm = {4}>
																<Typography variant='subtitle2' style={{ color: '#6b6b6b' }}> 学歴 </Typography>
																<Typography variant='body1' style={{ wordBreak: 'break-word' }}> {uni.education_background || 'NA'} </Typography>
															</Grid>
															<Grid item sm = {4}>
																<Typography variant='subtitle2' style={{ color: '#6b6b6b' }}> 学部 </Typography>
																<Typography variant='body1' style={{ wordBreak: 'break-word' }}> {uni.faculty || 'NA'} </Typography>
															</Grid>
															<Grid item sm = {4}>
																<Typography variant='subtitle2' style={{ color: '#6b6b6b' }}> 学科 </Typography>
																<Typography variant='body1' style={{ wordBreak: 'break-word' }}> {uni.department || 'NA'} </Typography>
															</Grid>
														</Grid>
														{i !== profile.universities?.length -1 && <Divider className='mt-8 mb-8' /> }
													</Grid>
												</Grid>
											))}
										</Grid>
									</Card>
									{this.renderCustomFields (profile.custom_fields).categories}
									<Card className = 'mt-12'>
										<Grid container spacing = {2} className= 'p-12 pb-8' style = {{borderLeft : '12px solid #03a9f5'}} alignItems = 'center'>
											<Typography variant = 'h6' style = {{fontSize : '17px'}}>
												<FontAwesomeIcon className = 'mr-8' icon = {faGraduationCap}/>
											</Typography>
										</Grid>
										<Divider className= 'mt-8'/>
										<Grid className = 'p-12'>
											{profile['name'] && <Grid container spacing = {2} alignItems = 'flex-start'>
												<Grid item sm = {3}>
													<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> 学生名 </Typography>
												</Grid>
												<Grid item sm = {9}>
													<Typography variant = 'body1'> {profile['name']} </Typography>
													<Divider className = 'mt-4 mb-4'/>
												</Grid>
											</Grid> }
											{profile['email'] && <Grid container spacing = {2} alignItems = 'flex-start'>
												<Grid item sm = {3}>
													<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> Email </Typography>
												</Grid>
												<Grid item sm = {9}>
													<Typography variant = 'body1'> {profile['email']} </Typography>
													<Divider className = 'mt-4 mb-4'/>
												</Grid>
											</Grid> }
											{profile['age'] && <Grid container spacing = {2} alignItems = 'flex-start'>
												<Grid item sm = {3}>
													<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> 年齢 </Typography>
												</Grid>
												<Grid item sm = {9}>
													<Typography variant = 'body1'> {profile['age']} </Typography>
													<Divider className = 'mt-4 mb-4'/>
												</Grid>
											</Grid> }
											{profile['master_university']
												? null
												: <div>
													{profile['bachelor_university'] && <Grid container spacing = {2} alignItems = 'flex-start'>
														<Grid item sm = {3}>
															<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> 大学 </Typography>
														</Grid>
														<Grid item sm = {9}>
															<Typography variant = 'body1'> {profile['bachelor_university']} </Typography>
															<Divider className = 'mt-4 mb-4'/>
														</Grid>
													</Grid> }
												</div>
											}
											{profile['master_university'] &&<Grid container spacing = {2} alignItems = 'flex-start'>
												<Grid item sm = {3}>
													<Typography variant = 'subtitle2' style = {{color : '#6b6b6b'}}> 大学 </Typography>
												</Grid>
												<Grid item sm = {9}>
													<Typography variant = 'body1'> {profile['master_university']} </Typography>
													<Divider className = 'mt-4 mb-4'/>
												</Grid>
											</Grid>
											}
										</Grid>
									</Card>
									{this.renderCustomFields (profile.custom_fields).cards}
								</Grid>
								<Grid item xs = {12} sm = {4} md = {3} lg = {3} xl = {3}>
									<Paper elevation = {2} className = 'p-12'>
										{this.state.picProfile && this.state.picProfile.participation || this.state.isInterview ?
											<Button variant = 'contained' color = 'primary' fullWidth  onClick = {this.downloadPdf}>
												{this.state.pdfLoading ? <Loader color = 'secondary'/> :
													<div>
														<DownloadIcon />
														<span style = {{position: 'relative',bottom:'8px',left: '12px'}}>レジュメ</span>
													</div> }
											</Button> : null}
										{this.renderDownloadDoc ()}
										{this.renderSideBarButton ()}
										{this.state.isInterview || this.state.page === 'HISTORY' ?  null : this.renderCartButton ()}
									</Paper>
									
								</Grid>
								{this.state.jobModal ?
									<Dialog open = {true} onBackdropClick = {this.closeJobModal}>
										<DialogTitle > ジョブの選択 </DialogTitle>
										<List>
											{this.state.picProfile.jobs.map((job) => (
												<ListItem button onClick={() => this.addToCart(job.job_id)} key={job.job_id}>
													<ListItemText primary={job.job_title} />
												</ListItem>
											))}
										</List>
									</Dialog>
									: null}
							</Grid>
						}
						<Grid item xl = {1}>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(ApplicantProfile);
