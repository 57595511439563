import {createStore} from 'redux';

let initState = {
	landingDetails   : null,
	picProfile       : null,
	jobId            : null,
	redirectCheckout : false,
};

export function landingInfo (landing) {
	
	return {
		type      : 'LANDING_INFO',
		landing   : landing,
	};
}

export function picProfileInfo (profile) {
	
	return {
		type    : 'PROFILE',
		profile : profile,
	};
}

export function selectedJob (jobId) {
	
	return {
		type : 'SELECTED_JOB',
		jobId,
	};
}

export function isredirected (bool) {

	return {
		type : 'REDIRECT_CHECKOUT',
		bool,
	};
}

function authReducer (authStore = initState, action) {
	
	switch (action.type) {

		case 'LANDING_INFO' :
			return {
				...authStore,
				landingDetails : action.landing,
			};

		case 'PROFILE' :
			return {
				...authStore,
				picProfile : action.profile,
			};

		case 'SELECTED_JOB':
			return {
				...authStore,
				jobId : action.jobId,
			};

		case 'REDIRECT_CHECKOUT':
			return {
				...authStore,
				redirectCheckout : action.bool,
			};

		default :
			return authStore;
	}
}

const authStore = createStore (authReducer, initState);

export default authStore;
