import React                            from 'react';
import {withSnackbar}                   from 'notistack';
import Grid                             from '@material-ui/core/Grid';
import Paper                            from '@material-ui/core/Paper';
import Typography                       from '@material-ui/core/Typography';
import Hidden                           from '@material-ui/core/Hidden';
import Select                           from '@material-ui/core/Select';
import MenuItem                         from '@material-ui/core/MenuItem';

import TopNav                           from 'components/molecules/TopNav';
import MobileNav                        from 'components/molecules/MobileNav';
import ApplicantCard                    from 'components/molecules/ApplicantCard';
import Pagination                       from 'components/molecules/Pagination';
import authStore, {picProfileInfo}      from 'redux/authStore';
import apis                             from 'common/apis';
import Log                              from 'common/log';
import {errorMsg}                       from 'common/errors';
import utility                          from 'common/utility';
import Skeleton                         from './Skeleton';

const log = Log ('HistoryPage', 'info');

class HistoryPage extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav     : false,
			loading       : true,
			isFetched     : false,
			picProfile    : null,
			orders        : [],
			cart          : [],
			events        : [],
			shortlisted   : [],
			selectedEvent : "",
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		this.getProfile ();
		this.getCart ();
		this.getOrderHistory ();
	}

	handleEventChange = (event) => {

		let eventId = event.target.value;
		let shortlisted = this.state.orders.find (o => o.sgwj_event_id === eventId).shortlisted_applicants;

		this.setState ({
			shortlisted,
			selectedEvent : eventId,
		});
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs.length ? picProfile.jobs[0].job_id : '';
		this.setState ({
			picProfile,
			jobId,
		});
	}

	getOrderHistory = async () => {
		
		let orders;

		try {
			orders = await apis.orderHistory ();
		}
		catch (err) {
			log.error ({err}, 'error getting order history');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		log.info ({orders}, 'order history get ok');

		let events = orders.order_history.map (o => {
			return {
				sgwj_event_id : o.sgwj_event_id,
				sgwj_event_name : o.sgwj_event_name,
			};
		});

		if (!events.length) {
			this.setState ({
				loading       : false,
				isFetched     : true,
			});
			return;
		}

		this.setState ({
			orders        : orders.order_history,
			events        : events,
			selectedEvent : events[0].sgwj_event_id,
			shortlisted   : orders.order_history[0].shortlisted_applicants,
			loading       : false,
			isFetched     : true,
		});
	}

	renderHistory = () => {
	
		if (!this.state.shortlisted.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 選出された学生はいません。</Typography>
			);
		}

		return (
			<Pagination data = {this.state.shortlisted.map ((applicant, index) => (
				<ApplicantCard
					applicant    = {applicant}
					key          = {index}
					cart         = {this.state.cart}
					jobName      = {applicant.job_name}
					hidePersonal = {true}
					page         = "HISTORY"
				/>
			))}
			/>
		);
	}

	render () {
	
		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing = {3}>
						<Grid item lg = {1} xl = {2}>
						</Grid>
						<Grid item lg = {10} xl = {8} md = {12} sm = {12} xs = {12}>
							<Paper elevation = {2} className = 'p-16'>
								<Grid container spacing = {2} alignItems = 'center'>
									<Grid item sm = {6}>
										<Typography variant = 'h4'>検討リスト履歴 </Typography>
									</Grid>
									<Grid item sm = {6} container justify = 'flex-end'>
										{this.state.shortlisted.length ?
											<Select value = {this.state.selectedEvent} variant = 'outlined' onChange = {this.handleEventChange}>
												{this.state.events.map (e => (
													<MenuItem value = {e.sgwj_event_id} key = {e.sgwj_event_id}> {e.sgwj_event_name}</MenuItem>
												))}
											</Select> :
											null}
									</Grid>
								</Grid>
							</Paper>
							{this.state.loading ? <Skeleton /> : this.renderHistory ()}
						</Grid>
						<Grid item xl = {1} lg = {2}>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(HistoryPage);
