export const styles = {
	
	email : {
		fontStyle : 'italic',
		color     : '#6b6b6b',
	},

	aid :{
		color : '#4b4b4b'
	},

	name : {
		color : '#288bfa',
	},

	recommended : {
		border: '2px solid #e64021',
		color: '#e64021',
		padding: '0px 4px',
		borderRadius: '18px',
		fontSize : '12px',
	},

	matched : {
		border: '2px solid #4CAF50',
		color: '#4caf50',
		padding: '4px',
		borderRadius: '18px'
	},
	icons : {
		height: '16px',
		fontWeight: 'initial',
		width: 'auto',
		marginRight: '8px',
	},
	event : {
		border: `1px solid #288bfa`,
		padding: `0px 8px`,
		borderRadius: `8px`,
		display : 'inline-flex',
		margin : '4px',
	},
	highSpec : {
		background   : '#ffc107',
		borderRadius : '6px',
		padding      : '0px 12px',
		fontWeight   : 'bold',
		marginLeft   : '12px'
	}
};
