import React                                                 from 'react';
import {Page, Document, StyleSheet, Font, View, Image}       from '@react-pdf/renderer';
import fontPathRegular                                       from 'styles/fonts/Koruri-Regular.ttf';
import fontPathBold                                          from 'styles/fonts/Koruri-Bold.ttf';
import fontPathSemiBold                                      from 'styles/fonts/Koruri-Semibold.ttf';
import Header                                                from './Header';
import Footer                                                from './Footer';
import PersonalDetails                                       from './PersonalDetails';
import Languages                                             from './Languages';
import Skills                                                from './Skills';
import Education                                             from './Education';
import Research                                              from './Research';
import Internship                                            from './Internship';
import Additional                                            from './Additional';
import BasicInfo                                             from './BasicInfo';

const Resume = (props) => {

	let studentData = props.studentData;
	return (
		<Document author="STUDY GO WORK JAPAN"  title={studentData.id}>
			<Page size="A4" style={styles.page}>
				<Header studentData = {studentData}/>
				<View style={styles.row}>
					<View style = {styles.colLeft}>
						{studentData.photograph ? <Image src = {studentData.photograph} alt = "photo" style = {{height : 150, width : 150}}/> : studentData.thumbnail ? <Image src = {studentData.thumbnail} alt = 'photo'/> : null}
					</View>
					{props.isInterview ?
						<View style = {styles.col}>
							<BasicInfo studentData = {studentData} />
						</View>
						: null}
				</View>
				<View style={styles.row}>
					<View style={styles.colLeft}>
						<PersonalDetails studentData = {studentData} />
					</View>
					<View style = {styles.col}>
						<Education studentData = {studentData} />
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.colLeft}>
						<Languages studentData = {studentData} />
					</View>
					<View style = {styles.col}>
						<Skills studentData = {studentData} />
					</View>
				</View>
				<View style = {styles.row}>
					<Research studentData = {studentData}/>
				</View>
				<View style = {styles.row}>
					<Internship studentData = {studentData}/>
				</View>
				<View style = {styles.row}>
					<Additional studentData = {studentData}/>
				</View>
				<Footer />
			</Page>
		</Document>
	);
};

export default Resume;

Font.register( {
	family: 'Lato',
	src: fontPathRegular,
});

Font.register( {
	family: 'Lato Italic',
	src: fontPathSemiBold,
});

Font.register( {
	family: 'Lato Bold',
	src: fontPathBold,
});

const styles = StyleSheet.create({

	page: {
		padding: 30,
	},
	container: {
		flex: 1,
		flexDirection: 'row',
		'@media max-width: 400': {
			flexDirection: 'column',
		},
	},
	image: {
		marginBottom: 10,
		marginRight: 20,
		width: "auto",
		height : 40,
	},
	leftColumn: {
		flexDirection: 'column',
		width: 170,
		paddingTop: 30,
		paddingRight: 15,
		'@media max-width: 400': {
			width: '100%',
			paddingRight: 0,
		},
		'@media orientation: landscape': {
			width: 200,
		},
	},
	row: {
		display : 'flex',
		flexDirection: 'row',
		width   : '100%',
		borderBottomWidth: 0.7,
		borderBottomColor: '#555',
		borderBottomStyle: 'solid',
		paddingBottom : 10,
		marginBottom : 10,
	},
	col: {
		flexBasis: 0,
		flexGrow: 1,
		maxWidth: "60%",
		paddingRight: 15,
	},
	colLeft: {
		flexBasis: 0,
		flexGrow: 1,
		maxWidth: "40%",
		paddingRight: 15,
	},
	basicContainer : {
		display : 'flex',
		flexDirection: 'row',
		width   : '100%',
		borderBottomWidth: 0.7,
		borderBottomColor: '#555',
		borderBottomStyle: 'solid',
		paddingBottom : 10,
		marginBottom : 10
	},
	footer: {
		fontSize: 12,
		fontFamily: 'Lato',
		textAlign: 'center',
		marginTop: 0,
		padding: 5,
		borderWidth: 1,
		borderColor: 'gray',
		borderStyle: 'dashed',
		'@media orientation: landscape': {
			marginTop: 10,
		},
	},
});
