import React                      from 'react';
import {Redirect}                 from 'react-router-dom';
import {withSnackbar}             from 'notistack';
import Grid                       from '@material-ui/core/Grid';
import swal                       from 'sweetalert';
import Typography                 from '@material-ui/core/Typography';
import FormHelperText             from '@material-ui/core/FormHelperText';
import TextField                  from '@material-ui/core/TextField';
import Button                     from '@material-ui/core/Button';
import Person                     from '@material-ui/icons/Person';
import Lock                       from '@material-ui/icons/Lock';

import utility                    from 'common/utility';
import apis                       from 'common/apis';
import path                       from 'common/path';
import Log                        from 'common/log';
import Loader                     from 'components/atoms/Loader';
import Logo                       from 'assets/Logo.png';
import {styles}                   from './style';
import ResetMessage               from './ResetMessage';

const log = Log ('ForgotPassword', 'info');

class ForgotPassword extends React.Component {
	
	constructor (props) {
		super (props);

		this.state = {
			email        : '',
			password     : '',
			errors       : {
				email    : false,
				password : false
			},
			redirectLogin : false,
			forgotMsg    : '',
			isLoading    : false,
			isAuthenticated : false,
			resetMessage : false,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	handleChange = (event) => {
		
		const {name, value} = event.currentTarget;

		this.setState ({
			[name] : value,
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleEnterPress = (event) => {
		
		if (event.keyCode !== 13) {
			return;
		}

		this.onForgotPassword ();
	}

	onForgotPassword = async () => {
		
		let email    = this.state.email;
		let errors = {};

		if (!utility.validateEmail (email)) {
			errors.email = true;
		}

		if (Object.keys (errors).length) {
			this.setState ({
				errors : {
					...errors
				}
			});
			return;
		}

		let data = {
			company_pic : {
				email,
			},
		};
		let result;

		this.setState ({
			isLoading : true,
		});

		try {
			result = await apis.forgotPassword (data);
			log.info ({result}, 'forgot password link');
		}

		catch (err) {
			log.error ({err}, 'failed to forgot password');
			this.notify ('Something went wrong', {variant : 'error'});
			this.setState ({
				isLoading : false,
			});
			return;
		}

		if (result.errors) {
			this.setState ({
				forgotMsg : result.errors,
				isLoading   : false,
			});
			return;
		}

		if (result.message) {
			this.setState ({
				isLoading : false,
			});

			swal ({
				title  : 	result.message,
				//text   :  result.message,
				icon   : 'success',
				button : 'Ok'
			}).then (() => {
				this.setState ({
					resetMessage : true,
				});
			});
		}
	}

	renderStartAdornment = (name) => {

		return (
			name === 'email' ? <Person style = {styles.icon}/> : <Lock style = {styles.icon}/>
		);
	}

	render () {

		if (this.state.resetMessage) {
			return (
				<ResetMessage />
			);
		}

		if (this.state.redirectLogin) {
			return (
				<Redirect to = {path.login} push = {true}/>
			);
		}
		
		return (
			<Grid container spacing = {3} style = {styles.mainContainer} alignItems = 'center' justify = 'center'>
				<Grid item xs = {12} sm = {8} md = {6} lg = {4} xl = {4}>
					<Grid style = {styles.loginContainer}>
						<Grid container justify = 'center'>
							<img alt= 'Logo' src = {Logo} height = {65} width = {70} className = 'mb-24'/>
						</Grid>
						<Typography variant = 'h4' align = 'center' className = 'mb-16'> パスワードをリセットする </Typography>
						<Grid style = {styles.inputContainer}>
							<TextField
								placeholder = 'メール'
								variant     = 'outlined'
								value       = {this.state.email}
								error       = {this.state.errors.email}
								fullWidth   = {true}
								name        = 'email'
								onChange    = {this.handleChange}
								type        = 'email'
								onKeyUp     = {this.handleEnterPress}
								InputProps  = {{
									style : styles.inputProps,
									startAdornment : this.renderStartAdornment ('email')
								}}
							/>
							{this.state.errors.email ? <FormHelperText error = {true}> メールアドレスは有効ではありません。</FormHelperText> : null}
						</Grid>
						<Button variant = 'contained' color = 'primary' fullWidth onClick = {this.onForgotPassword} disabled = {this.state.isLoading}>
							{this.state.isLoading ? <Loader /> : 'パスワードをリセットする'}
						</Button>
					</Grid>
					{this.state.forgotMsg ? <FormHelperText error = {true} style = {styles.forgotMsg}> {this.state.forgotMsg} </FormHelperText> : null}
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(ForgotPassword);
